import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import FeedItem from "../Shared/FeedItem"

function DashboardFeed() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const userName = userDataState.first_name.charAt(0).toUpperCase() + userDataState.first_name.slice(1)
    const translation = useSelector(selectTranslations)
    return (
        <div className="dashboard-feed">
            <div className="dashboard-feed__header">
                <div className="dashboard-feed__text">
                    <div className="dashboard-feed__text dashboard-feed__text--font-size-36 dashboard-feed__text--font-weight-700 dashboard-feed__text--margin-bottom-06">
                        {translation.hello} {userName},
                    </div>
                    <div className="dashboard-feed__text dashboard-feed__text--font-size-18 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-bottom-27">
                        {translation.feedWelcomeText}
                    </div>
                    <div className="dashboard-feed__text dashboard-feed__text--font-size-18 dashboard-feed__text--font-weight-700 ">
                        {translation.yourRooms}
                    </div>
                </div>
            </div>
            <div className="dashboard-feed__content">
                <FeedItem />
                <FeedItem />
                <FeedItem />
                <FeedItem />
            </div>
        </div>
    )
}

export default DashboardFeed
