import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import StorageService, { StorageKey } from "../../App/StorageService"
import { store } from "../../App/store"
import { setUserData } from "../SharedSlices/UserDataSlice"
import { hasAuthenticationToken } from "../SignIn/SigninSlice"

export function logOut() {
    let refreshToken = StorageService.getItem(StorageKey.refreshToken) as string

    HTTPService.post("/logout/", { refresh: refreshToken }, headers)
        .then(response => {
            if (response.status === 205) {
                store.dispatch(
                    setUserData({
                        id: "",
                        email: "",
                        city: "",
                        country: "",
                        cover_photo: "",
                        first_name: "",
                        last_name: "",
                        description: "",
                        edusiia_reasons: "",
                        profile_photo: "",
                    })
                )
                StorageService.clear()
                store.dispatch(hasAuthenticationToken({ hasAccessToken: false }))
            }
        })
        .catch(error => {
            console.log(error)
        })
}
