import React from "react"
import "../../Styles/main.scss"

function UserProfileDescriptionItem(props:{title: string, description: string}) {
    return (
        <div className="user-profile-description-item">
            <div className="user-profile-description-item__title">{props.title}</div>
            <div className="user-profile-description-item__text">
               {props.description}
            </div>
        </div>
    )
}

export default UserProfileDescriptionItem