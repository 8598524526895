import React from "react"
import "../../Styles/main.scss"
import Card from "./Primitive/Card"
import UserCard from "./UserCard"

function FeedItem() {
    return (
        <div className="feed-item">
            <Card>
                <div className="feed-item--padding-1">
                    <UserCard
                        imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAd8H09bHQj8r9LMiBow7xboJR4bX997Ai5w&usqp=CAU"
                        text="#MainRoom"
                        subtext="by Organization Name"
                        rightText="Private"
                        userCardStyle={"feed-item-room-card"}
                        leftPanelStyle={"left-panel"}
                        middlePanelStyle={"middle-panel"}
                        rightPanelStyle={"right-panel"}
                        profileImageStyle={"image image__profile image__profile--fit image__profile--size-medium"}
                        textStyle={"text"}
                        subtextStyle={"subtext"}
                        rightTextStyle={"right-text"}
                    />

                    <div className="feed-item__text-container">
                        <div className="feed-item__text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem dolorum sequi fugit rerum, magni accusamus fugiat
                            consequuntur ea, mollitia dolores aliquid laborum doloribus culpa facere? Distinctio libero unde quidem fuga non est rem debitis
                            quod consequatur architecto, dolores velit itaque.
                        </div>{" "}
                    </div>

                    <UserCard
                        imageSrc="https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg"
                        text="Name Surname"
                        subtext="15:20 PM"
                        rightText="9 Oct"
                        userCardStyle={"feed-item-profile-card"}
                        leftPanelStyle={"left-panel"}
                        middlePanelStyle={"middle-panel"}
                        rightPanelStyle={"right-panel"}
                        profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                        textStyle={"text"}
                        subtextStyle={"subtext"}
                        rightTextStyle={"right-text"}
                    />
                </div>
            </Card>
        </div>
    )
}

export default FeedItem
