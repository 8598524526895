import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DeleteAccountErrorResponseType } from "../../../Types/Responses/DeleteAccountErrorResponseType"

const initialState: DeleteAccountErrorResponseType = { message: { reason: "" }, status: 0 }

const DeleteAccountErrorSlice = createSlice({
    name: "deleteAccountError",
    initialState: initialState,
    reducers: {
        getDeleteAccountError(state, action: PayloadAction<DeleteAccountErrorResponseType>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})

export const { getDeleteAccountError } = DeleteAccountErrorSlice.actions
export default DeleteAccountErrorSlice.reducer
