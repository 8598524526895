import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { SearchIcon } from "../SvgIcons"

function SearchInput() {
    const translation = useSelector(selectTranslations)
    return (
        <div className="search-input">
            <input className="search-input__search-field" placeholder={translation.Search} />
            <div className="search-input__search-icon">
                <SearchIcon />
            </div>
        </div>
    )
}

export default SearchInput
