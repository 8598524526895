import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { WorkExperience } from "../../Types/Responses/WorkExperience"
import { WorkExperienceData } from "../../Types/WorkExperienceData"

const initialState: WorkExperience = { work: [] }

const WorkExperienceSlice = createSlice({
    name: "workExperienceSlice",
    initialState: initialState,
    reducers: {
        setWorkExperienceData(state, action: PayloadAction<Array<WorkExperienceData>>) {
            state.work = action.payload
        },
    },
})

export const { setWorkExperienceData } = WorkExperienceSlice.actions
export default WorkExperienceSlice.reducer
