import { configureStore } from "@reduxjs/toolkit"
import signupData from "../Module/SignUp/SignupSlice"
import authenticationSlice from "../Module/SignIn/SigninSlice"
import forgotPasswordError from "../Module/ForgotPassword/ForgotPasswordSlice"
import edusiiaMatchingStatus from "../Module/EdusiiaMatching/EdusiiaMatchingSlice"
import resetPasswordStatus from "../Module/ResetPassword/ResetPasswordSlice"
import i18nSlice from "./i18n/i18nSlice"
import UserDataSlice from "../Module/SharedSlices/UserDataSlice"
import DeleteAccountSlice from "../Module/Shared/DeleteAccount/DeleteAccountSlice"
import SettingsProfileErrorSlice from "../Module/Shared/Settings/SettingsProfileSlice"
import NewestMembersSlice from "../Module/SharedSlices/NewestMembersSlice"
import SponsorCardSlice from "../Module/SharedSlices/SponsorCardSlice"
import RoomSlice from "../Module/SharedSlices/RoomSlice"
import WorkExperienceSlice from "../Module/SharedSlices/WorkExperienceSlice"

export const store = configureStore({
    reducer: {
        signupSlice: signupData,
        signinSlice: authenticationSlice,
        forgotpasswordSlice: forgotPasswordError,
        EdusiiaMatchingSlice: edusiiaMatchingStatus,
        resetPasswordSlice: resetPasswordStatus,
        i18n: i18nSlice,
        userDataSlice: UserDataSlice,
        roomSlice: RoomSlice,
        workExperienceSlice: WorkExperienceSlice,
        deleteAccount: DeleteAccountSlice,
        settingsProfileSlice: SettingsProfileErrorSlice,
        newMembers: NewestMembersSlice,
        sponsorSlice: SponsorCardSlice,

    },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
