import React from "react"
import Image from "./Primitive/Image"
function UserCard(props: {
    userCardStyle?: string
    leftPanelStyle?: string
    middlePanelStyle?: string
    rightPanelStyle?: string
    textStyle?: string
    subtextStyle?: string
    rightTextStyle?: string
    profileImageStyle?: string
    imageSrc?: string
    text?: string
    subtext?: string
    rightText?: string
}) {
    return (
        <div className={props.userCardStyle}>
            <div className={`${props.userCardStyle}__${props.leftPanelStyle}`}>
                <Image src={props.imageSrc} className={`${props.userCardStyle}__${props.profileImageStyle}`} />
            </div>
            <div className={`${props.userCardStyle}__${props.middlePanelStyle}`}>
                <div className={`${props.userCardStyle}__${props.textStyle}`}> {props.text} </div>
                <div className={`${props.userCardStyle}__${props.subtextStyle}`}> {props.subtext} </div>
            </div>
            <div className={`${props.userCardStyle}__${props.rightPanelStyle}`}>
                <div className={`${props.userCardStyle}__${props.rightTextStyle}`}> {props.rightText} </div>
            </div>
        </div>
    )
}
export default UserCard
