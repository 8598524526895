import HTTPService from "../../App/HTTPService"
import StorageService, { StorageKey } from "../../App/StorageService"
import { setUserData } from "../SharedSlices/UserDataSlice"
import { getSigninError, hasAuthenticationToken } from "./SigninSlice"

export const getUserRequest = (id: number) => {
    return HTTPService.get(`/profile/${id}/`)
}

export const signinPostRequest = (email: string, password: string) => async (dispatch: Function) => {
    const loginData = {
        email: email,
        password: password,
    }

    try {
        const response = await HTTPService.post("/login/", loginData)
        if (response.status === 200) {
            const accessToken = response.data.tokens.access
            const refreshToken = response.data.tokens.refresh
            const id = response.data.id

            StorageService.setItem(StorageKey.accessToken, accessToken)
            StorageService.setItem(StorageKey.refreshToken, refreshToken)

            getUserRequest(id)
                .then(response => {
                    if (response.status === 200) {
                        const user = {
                            id: response.data.id,
                            email: response.data.email,
                            city: response.data.city,
                            country: response.data.country,
                            cover_photo: response.data.cover_photo,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            description: response.data.description,
                            edusiia_reasons: response.data.edusiia_reasons,
                            profile_photo: response.data.profile_photo,
                            privacy_settings: response.data.privacy_settings,
                        }

                        StorageService.setItem("user", StorageService.stringify(user))
                        dispatch(hasAuthenticationToken({ hasAccessToken: true }))
                        dispatch(
                            setUserData({
                                id: user.id,
                                email: user.email,
                                city: user.city,
                                country: user.country,
                                cover_photo: user.cover_photo,
                                first_name: user.first_name,
                                last_name: user.last_name,
                                description: user.description,
                                edusiia_reasons: user.edusiia_reasons,
                                profile_photo: user.profile_photo,
                                privacy_settings: user.privacy_settings,
                            })
                        )
                    }
                })
                .catch(error => {
                    console.log(error.response)
                })
        }
    } catch (error: any) {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }

        await dispatch(
            getSigninError({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}
