import HTTPService from "../../App/HTTPService"
import { StorageKey } from "../../App/StorageService"
import { getForgotPasswordError, getStatus } from "./ForgotPasswordSlice"

export const forgotPasswordPostRequest = (props: string) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.post("/forgot-password/", {
            email: props,
        })
        if (response.status === 200) {
            sessionStorage.setItem(StorageKey.email, props)
            await dispatch(getStatus())
        }
    } catch (error: any) {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }
        await dispatch(
            getForgotPasswordError({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}
