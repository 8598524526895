import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Room } from "../../Types/Room"
import { RoomData } from "../../Types/RoomData"

const initialState: RoomData = { rooms: [] }

const RoomSlice = createSlice({
    name: "RoomSlice",
    initialState: initialState,
    reducers: {
        setRoomData(state, action: PayloadAction<Array<Room>>) {
            state.rooms = action.payload
        },
    },
})
export const { setRoomData } = RoomSlice.actions
export default RoomSlice.reducer
