import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SettingsProfileResponseMessage } from "../../../Types/Responses/SettingsProfileResponseMessage"

interface ErrorData {
    message: SettingsProfileResponseMessage
    status: number
}
const initialState: ErrorData = {
    message: { confirm_password: "", email: "", first_name: "", last_name: "", old_password: "", password: "", non_field_errors: "" },
    status: 0,
}

const SettingsProfileErrorSlice = createSlice({
    name: "SettingsProfileError",
    initialState: initialState,
    reducers: {
        getSettingsProfileError(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})
export const { getSettingsProfileError } = SettingsProfileErrorSlice.actions
export default SettingsProfileErrorSlice.reducer
