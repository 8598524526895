import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { RootState } from "../store"
import { RouterProps } from "./RoutingType"

const PrivateRoute = ({ Component }: RouterProps) => {
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice)
    const authenticated = isAuthenticated.hasAccessToken

    return authenticated ? <Component /> : <Navigate to="/" />
}
export default PrivateRoute
