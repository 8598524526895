import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import { LogoIcon } from "../Shared/SvgIcons"
import TextInput from "../Shared/Primitive/TextInput"
import { resetPasswordPatchRequest } from "./ResetPasswordServiceRequests"
import { selectTranslations } from "../../App/i18n/i18nSlice"

const ResetPasswordForm = () => {
    const navigate = useNavigate()
    let dispatch = useDispatch()
    const errorState = useSelector((state: RootState) => state.resetPasswordSlice.resetPasswordError)
    const statusState = useSelector((state: RootState) => state.resetPasswordSlice.resetPasswordStatus)
    const translation = useSelector(selectTranslations)

    const [password, setPassword] = useState("")
    const [confirmpassword, setConfrimPassword] = useState("")
    const { token } = useParams()
    const { uidb64 } = useParams()

    const handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(resetPasswordPatchRequest(password, confirmpassword, token, uidb64))
    }

    useEffect(() => {
        if (statusState.status === true) {
            navigate("/")
        }
    }, [statusState])

    return (
        <form className="reset-password-form" onSubmit={handleSubmit}>
            <div className="reset-password-form__logo">
                <LogoIcon />
            </div>
            <div className="reset-password-form__title">
                <h2> {translation.resetYourPassword} </h2>
            </div>
            <>
                <h4 className=" reset-password-form__subtitle ">
                    {translation.minimumCharacters} {translation.mustContain} <p className="h4__position">{translation.upperAndLowercaseNumbersAndSimbols}</p>
                </h4>
            </>

            <div className="reset-password-form__single-input">
                <p className="p3 reset-password-form__small-text">{translation.newPassword}</p>
                <TextInput
                    type="password"
                    textinputsize="large"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                />
                <p className="p3  reset-password-form__error-message">{errorState.message.password}</p>
            </div>
            <div className="reset-password-form__single-input">
                <p className="p3 reset-password-form__small-text">{translation.confirmNewPassword}</p>
                <TextInput
                    type="password"
                    textinputsize="large"
                    value={confirmpassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfrimPassword(event.target.value)}
                />

                <p className="p3  reset-password-form__error-message">
                    {errorState.message.confirm_password} {errorState.message.non_field_errors}
                </p>
            </div>
            <div className="reset-password-form__button">
                <Button className="button button--medium button--color-red button--text-color-white">
                    <div>{translation.changePassword}</div>
                </Button>
            </div>
        </form>
    )
}

export default ResetPasswordForm
