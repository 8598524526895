import React from "react"
import "../../Styles/main.scss"
import { CameraIcon, CameraSmallIcon, EditProfileIcon, EditProfileSmallIcon, LocationIcon } from "../Shared/SvgIcons"
import Image from "../Shared/Primitive/Image"
import { useSelector } from "react-redux"
import { RootState } from "../../App/store"
import { HeaderDummyData } from "./HeaderDummyData"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import Tag from "../Shared/Tag"

function UserProfileHeader() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)

    return (
        <div className="profile-header">
            <div className="profile-header__cover-image-container">
                <Image className="profile-header__cover-image " src={HeaderDummyData.cover_photo} />
                <div className="profile-header__camera-icon">
                    <CameraIcon />
                </div>
                <div className="profile-header__camera-small-icon">
                    <CameraSmallIcon />
                </div>
            </div>
            <Image className="profile-header__profile-image" src={HeaderDummyData.profile_photo} />
            <div className="profile-header__user-name">
                <> {HeaderDummyData.first_name + " " + HeaderDummyData.last_name}</>
                <div className="profile-header__edit-icon">
                    <EditProfileIcon />
                </div>
                <div className="profile-header__edit-small-icon">
                    <EditProfileSmallIcon />
                </div>
            </div>
            <div className="profile-header__tags-container">
                {HeaderDummyData.tags.map(item => {
                    return <div key={item.id}>{<Tag color="blue" children={item.tag} />}</div>
                })}
            </div>
            <div className="profile-header__location-container">
                <div className="profile-header__location">
                    <LocationIcon /> {HeaderDummyData.city + ", " + HeaderDummyData.country}
                </div>
            </div>
            <div className="profile-header__others-view">
                <p>{translation.howOthers}</p> <p>{translation.viewMyProfile}</p>
            </div>
        </div>
    )
}

export default UserProfileHeader
