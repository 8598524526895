import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SignupResponseMessage } from "../../Types/Responses/SignupResponseMessage"

interface Data {
    Name: string
    Last_name: string
    Email: string
    Password: string
    Confirm_password: string
    Reference_id: number
    privacy_settings: Array<{ private_messages: boolean; search_results: boolean }>
}
const initialDataState: Data = {
    Name: "",
    Last_name: "",
    Email: "",
    Password: "",
    Confirm_password: "",
    Reference_id: 0,
    privacy_settings: [
        {
            private_messages: true,
            search_results: true,
        },
    ],
}

const SignupDataSlice = createSlice({
    name: "signupData",
    initialState: initialDataState,
    reducers: {
        setFormData(state, action: PayloadAction<Data>) {
            state.Name = action.payload.Name
            state.Last_name = action.payload.Last_name
            state.Email = action.payload.Email
            state.Password = action.payload.Password
            state.Confirm_password = action.payload.Confirm_password
            state.Reference_id = action.payload.Reference_id
        },
    },
})

interface SignupErrorData {
    message: SignupResponseMessage
    status: number
}

const initialSignupErrorState: SignupErrorData = {
    message: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", reference_id: "", non_field_errors: "" },
    status: 0,
}

const SignupErrorSlice = createSlice({
    name: "signupError",
    initialState: initialSignupErrorState,
    reducers: {
        getSignupError(state, action: PayloadAction<SignupErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})

interface SignupFormStatus {
    status: boolean
}
const initialStatusState: SignupFormStatus = { status: false }

const SignupStatusSlice = createSlice({
    name: "signupStatusStatus",
    initialState: initialStatusState,
    reducers: {
        getSignupStatus(state, action: PayloadAction<SignupFormStatus>) {
            state.status = action.payload.status
        },
    },
})

const initialSignupEmailErrorState = { message: "" }

const SignupEmailErrorSlice = createSlice({
    name: "signupEmailError",
    initialState: initialSignupEmailErrorState,
    reducers: {
        setEmailError(state = initialSignupEmailErrorState) {
            state.message = "This email is already in use."
        },
    },
})

interface SignupErrorStatus {
    status: boolean
}
const initialErrorStatusState: SignupErrorStatus = { status: false }

const SignupErrorStatusSlice = createSlice({
    name: "signupErrorStatusStatus",
    initialState: initialErrorStatusState,
    reducers: {
        getSignupErrorStatus(state, action: PayloadAction<SignupErrorStatus>) {
            state.status = action.payload.status
        },
    },
})

export const { setFormData } = SignupDataSlice.actions
export const { getSignupStatus } = SignupStatusSlice.actions
export const { setEmailError } = SignupEmailErrorSlice.actions
export const { getSignupError } = SignupErrorSlice.actions
export const { getSignupErrorStatus } = SignupErrorStatusSlice.actions
export default combineReducers({
    signupData: SignupDataSlice.reducer,
    signupStatus: SignupStatusSlice.reducer,
    signupEmailError: SignupEmailErrorSlice.reducer,
    signupError: SignupErrorSlice.reducer,
    singupErrorStatus: SignupErrorStatusSlice.reducer,
})
