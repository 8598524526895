import React from "react"
import MainLayout from "../Shared/MainLayout"
import UserProfileHeader from "./UserProfileHeader"
import UserProfileExperience from "./UserProfileExperience"
import "../../Styles/main.scss"

function UserProfileExperienceScreen() {
    return (
        <MainLayout>
            <div className="user-profile-experience-screen">
                <UserProfileHeader />
                <UserProfileExperience hasButton={false} filterName={1000} />
            </div>
        </MainLayout>
    )
}

export default UserProfileExperienceScreen
