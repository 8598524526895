import React from "react"
import "../../../Styles/main.scss"

function Checkbox(
    props: { color: "red" | "dark-blue"; initialState?: boolean; isChecked: boolean; setIsChecked: Function } & React.HTMLAttributes<HTMLInputElement> &
        React.HTMLAttributes<HTMLLabelElement>
) {
    return (
        <label data-testid="checkbox-label-id" className="checkbox ">
            <input
                data-testid="checkbox-input-id"
                type="checkbox"
                value={props.title}
                onChange={event => {
                    props.setIsChecked(event.target.checked)
                }}
            />
            <span
                className={`checkbox__checkmark ${props.initialState === true ? "checkbox__checkmark--clicked" : ""} checkbox__checkmark--${props.color}`}
            ></span>
            {props.title}
        </label>
    )
}
export default Checkbox
