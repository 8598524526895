import React from "react"
import ReactDOM from "react-dom"
import App from "./App/App"
import reportWebVitals from "./App/reportWebVitals"
import { store } from "./App/store"
import { Provider } from "react-redux"
import StorageService, { StorageKey } from "./App/StorageService"
import { Language } from "./App/i18n/i18nConfig"

if (!StorageService.hasItem(StorageKey.language)) {
    StorageService.setItem(StorageKey.language, Language.english)
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)

reportWebVitals()
