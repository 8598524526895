import React, { useEffect, useState } from "react"
import { LogoIcon, TooltipQuestionmark } from "../Shared/SvgIcons"
import "../../Styles/main.scss"
import TextInput from "../Shared/Primitive/TextInput"
import Dropdown from "../Shared/Primitive/Dropdown"
import Button from "../Shared/Primitive/Button"
import Tooltip from "../Shared/Primitive/Tooltip"
import { useNavigate } from "react-router-dom"
import { emailGetRequest, dropdownGetRequest } from "./SignupServiceRequests"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../App/store"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { getSignupStatus } from "./SignupSlice"

function SignupForm() {
    const translation = useSelector(selectTranslations)

    let navigate = useNavigate()
    const dispatch = useDispatch()
    const statusState = useSelector((state: RootState) => state.signupSlice.signupStatus)
    const emailErrorState = useSelector((state: RootState) => state.signupSlice.signupEmailError)
    const errorState = useSelector((state: RootState) => state.signupSlice.signupError)

    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [itemId, setItemId] = useState(0)

    const [nameFieldError, setNameFieldError] = useState("")
    const [lastNameFieldError, setLastNameFieldError] = useState("")
    const [emailFieldError, setEmailFieldError] = useState("")
    const [passwordFieldError, setPasswordFieldError] = useState("")
    const [confirmPasswordFieldError, setConfirmPasswordFieldError] = useState("")
    const [idFieldError, setIdFieldError] = useState("")

    let validationSuccess: boolean = false

    const validateForm = () => {
        const validPassword = new RegExp("(?!^[0-9]*$)(?!^[a-zA-Z!@#$%^&*()_+=<>?]*$)^([a-zA-Z!@#$%^&*()_+=<>?0-9]{8,})$")
        const validEmail = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}")

        if (name.length === 0) {
            setNameFieldError(translation.thisFieldIsRequired)
        } else {
            setNameFieldError("")
            validationSuccess = true
        }
        if (lastName.length === 0) {
            setLastNameFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else {
            setLastNameFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (email.length === 0) {
            setEmailFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (validEmail.test(email)) {
            setEmailFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        } else {
            setEmailFieldError(translation.enterValidEmail)
            validationSuccess = false
        }
        if (password.length === 0) {
            setPasswordFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (!validPassword.test(password)) {
            setPasswordFieldError(translation.passwordLongerThan8)
            validationSuccess = false
        } else {
            setPasswordFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (confirmPassword.length === 0) {
            setConfirmPasswordFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (confirmPassword !== password) {
            setConfirmPasswordFieldError(translation.passwordsDontMatch)
            validationSuccess = false
        } else {
            setConfirmPasswordFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (itemId === 0) {
            setIdFieldError(translation.pleaseSelectAnAnswer)
            validationSuccess = false
        } else {
            setIdFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
    }

    const handleOnSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        validateForm()
        const id = itemId.toString()

        if (validationSuccess === true) {
            dispatch(emailGetRequest(name, lastName, email, password, confirmPassword, id))
        }
    }

    const [dropdownItems, setDropdownItems] = useState([{ id: 0, name: "" }])

    useEffect(() => {
        let isMounted = true
        dropdownGetRequest()
            .then(response => {
                if (isMounted) {
                    if (response.status === 200) {
                        setDropdownItems(response.data)
                    }
                }
            })
            .catch(error => {
                console.log(error.response)
            })
        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        if (statusState.status === true) {
            dispatch(getSignupStatus({ status: false }))
            navigate("/edusiiamatching")
        }
    }, [statusState])

    return (
        <form className="signup-form" onSubmit={handleOnSubmit}>
            <div className="signup-form__logo">
                {" "}
                <LogoIcon />
            </div>
            <div className="signup-form__title signup-form__title--big-margin">
                <h2 className="signup-form__title"> {translation.signup} </h2>
            </div>
            <div className="signup-form__double-input-container">
                <div className="double-input-container__single-input">
                    <p className="p3 signup-form__small-text">{translation.name}*</p>
                    <TextInput
                        type="text"
                        textinputsize="small"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                    />
                    <p className="p3  signup-form__error-message">
                        {nameFieldError}
                        {errorState.message.first_name}
                    </p>
                </div>
                <div className="double-input-container__single-input">
                    <p className="p3 signup-form__small-text">{translation.lastName}*</p>
                    <TextInput
                        type="text"
                        textinputsize="small"
                        placeholder="Surname"
                        value={lastName}
                        name="lastName"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                    />
                    <p className="p3  signup-form__error-message">
                        {lastNameFieldError}
                        {errorState.message.last_name}
                    </p>
                </div>
            </div>
            <div className="signup-form__single-input">
                <p className="p3 signup-form__small-text">{translation.email}*</p>
                <TextInput
                    type="text"
                    textinputsize="large"
                    placeholder="email"
                    name="email"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />
                <p className="p3  signup-form__error-message">
                    {emailFieldError}
                    {errorState.message.email}
                    {emailErrorState.message}
                </p>
            </div>
            <div className="signup-form__double-input-container">
                <div className="doubleInput--position">
                    <div className="signup-form__tooltip-container">
                        <p className="p3 signup-form__small-text-tooltip">{translation.securePassword}* </p>
                        <div className="signup-form__tooltip">
                            <Tooltip direction="right" content="Password need to be longer than 8 characters and contain at least one number and one letter.">
                                <TooltipQuestionmark />
                            </Tooltip>
                        </div>
                    </div>
                    <TextInput
                        type="password"
                        textinputsize="small"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    />
                    <p className="p3  signup-form__error-message">
                        {passwordFieldError}
                        {errorState.message.password}
                    </p>
                </div>
                <div className="doubleInput--position">
                    <p className="p3 signup-form__small-text">{translation.repeatPassword}*</p>
                    <TextInput
                        type="password"
                        textinputsize="small"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                    />
                    <p className="p3  signup-form__error-message">
                        {confirmPasswordFieldError}
                        {errorState.message.confirm_password}
                    </p>
                </div>
            </div>
            <>
                <h2 className=" signup-form__title signup-form__title--small-margin"> {translation.howDidYouLearnAboutEdusiia} </h2>
            </>
            <div>
                {" "}
                <div className="dropdown">
                    <Dropdown
                        itemId={itemId}
                        setItemId={setItemId}
                        items={dropdownItems}
                        placeholder={"How did you learn about edusiia?"}
                        tabIndex={dropdownItems[0].id}
                    />
                </div>
                <p className="p3  signup-form__error-message">
                    {idFieldError}
                    {errorState.message.reference_id}
                </p>
            </div>
            <div className="signup-form__button">
                <Button type="submit" className="button button--medium button--color-red button--text-color-white">
                    <div className="h4">{translation.next}</div>
                </Button>
            </div>
        </form>
    )
}
export default SignupForm
