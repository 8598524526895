import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import StorageService, { StorageKey } from "../../App/StorageService"
import { RootState } from "../../App/store"
import { RoomsResponse } from "../../Types/Responses/RoomResponse"
import { setRoomData } from "../SharedSlices/RoomSlice"
import DropdownItem from "./Primitive/DropdownItem"
import { CubeIcon, GreyVectorDown, GreyVectorRight } from "./SvgIcons"

function RoomDropdown() {
    const translation = useSelector(selectTranslations)
    const roomState = useSelector((state: RootState) => state.roomSlice.rooms)
    const [expanded, setExpanded] = useState(false)
    const dispatch = useDispatch()
    const user = StorageService.getItem(StorageKey.user) as string
    const objectUser = StorageService.parse(user)
    const roomGetRequest = () => {
        return HTTPService.get(`/user/${objectUser.id}/room/`, headers)
    }

    useEffect(() => {
        let isMounted = true
        roomGetRequest()
            .then((response: RoomsResponse) => {
                if (isMounted) {
                    if (response.status === 200) {
                        const data = response.data.room
                        dispatch(setRoomData(data))
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <div className="room-dropdown">
            <div className="room-dropdown__header" onClick={() => setExpanded(!expanded)}>
                <div className="">{expanded ? <GreyVectorDown /> : <GreyVectorRight />}</div>
                <div className="room-dropdown__cube-icon">
                    <CubeIcon />
                </div>
                <div className="room-dropdown__title"> {translation.Rooms} </div>
            </div>
            {expanded && (
                <div>
                    {roomState?.map(item => {
                        return (
                            <div className="room-dropdown__element" key={item.id}>
                                <DropdownItem name={item.name} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default RoomDropdown
