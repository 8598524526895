import HTTPService from "../../App/HTTPService"
import { getResetPasswordError, getResetPasswordStatus } from "./ResetPasswordSlice"
export const resetPasswordPatchRequest =
    (password: string, confirm_password: string, token: string | undefined, uidb64: string | undefined) => async (dispatch: Function) => {
        const resetPasswordData = {
            password: password,
            confirm_password: confirm_password,
            token: token,
            uidb64: uidb64,
        }
        try {
            const response = await HTTPService.patch("/password-change-done/", resetPasswordData)
            if (response.status === 200) {
                await dispatch(getResetPasswordStatus())
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }

            await dispatch(
                getResetPasswordError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }
