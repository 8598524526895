import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"

function UserProfileRoomsItem(props: { image: string; organizationName: string; type: string; roomName: string; members: string; date: string }) {
    const translation = useSelector(selectTranslations)

    return (
        <div className="profile-rooms-item">
            <div className="profile-rooms-item__image">
                <img className="image image__profile image__profile--fit image__profile--size-small" src={props.image} alt="Organization" />
            </div>
            <div className="profile-rooms-item__organization-name">
                <p className="profile-rooms-item__overflow">{props.organizationName}</p>
            </div>
            <div className="profile-rooms-item__type">
                <div className="profile-rooms-item__type-container">
                    <div className="profile-rooms-item__text">{props.type}</div>
                </div>
            </div>
            <div className="profile-rooms-item__room-name">
                <p className="profile-rooms-item__overflow">{props.roomName}</p>
            </div>
            <div className="profile-rooms-item__members">
                <div className="profile-rooms-item__members-container">
                    <div className="profile-rooms-item__text">
                        {props.members} {translation.members}
                    </div>
                </div>
            </div>
            <div className="profile-rooms-item__date">{props.date}</div>
        </div>
    )
}

export default UserProfileRoomsItem
