import DashboardFeed from "./DashboardFeed"
import MainLayout from "../Shared/MainLayout"

function DashboardScreen() {
    return (
        <MainLayout>
            <DashboardFeed />
        </MainLayout>
    )
}

export default DashboardScreen
