import React, { useEffect, useRef } from "react"
import HTTPService from "../../App/HTTPService"
import "../../Styles/main.scss"
import Image from "./Primitive/Image"
import { SponsorCardType } from "../../Types/SponsorCardType"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../App/store"
import { setSponsorCardData } from "../SharedSlices/SponsorCardSlice"
import { headers } from "../../App/Axios"

function SponsorCard() {
    const dispatch = useDispatch()
    const SponsorState = useSelector((state: RootState) => state.sponsorSlice.events)
    const sponsorSlider = useRef<HTMLDivElement>(null)
    let arrayLength = SponsorState.length

    const sponsoredEventGetRequest = () => {
        return HTTPService.get("/events/", headers)
    }

    useEffect(() => {
        let isMounted = true

        sponsoredEventGetRequest()
            .then((response: SponsorCardType) => {
                if (isMounted) {
                    if (response.status === 200) {
                        dispatch(setSponsorCardData(response.data))
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })

        return () => {
            isMounted = false
        }
    }, [])

    let index = 0

    if (arrayLength > 1) {
        setInterval(() => {
            if (index < arrayLength) {
                index++
            }

            if (index === arrayLength) {
                index = 0
            }

            sponsorSlider.current?.style.setProperty(`transform`, `translateX(calc((-100%)* ${index} ))`)
        }, 8000)
    }

    return (
        <div className="sponsor-card__slider-card" ref={sponsorSlider}>
            {SponsorState?.map(sponsor => {
                return (
                    <div className="sponsor-card" key={sponsor.id}>
                        <div className="sponsor-card__container">
                            <div className="sponsor-card__content">
                                <div className="sponsor-card__organization">
                                    <div className="sponsor-card__organization-image">
                                        <Image
                                            className="image image__profile image__profile--size-small"
                                            src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_3.jpg"
                                        />
                                    </div>
                                    <div className="sponsor-card__organization-name"> {sponsor?.organization} </div>
                                </div>
                                <div className="sponsor-card__title">
                                    <div className="sponsor-card__bold-title" title={sponsor?.title}>
                                        {" "}
                                        {sponsor?.title}{" "}
                                    </div>
                                    {sponsor?.subtitle ? (
                                        <div className="sponsor-card__sub-title" title={sponsor?.subtitle}>
                                            {" "}
                                            | {sponsor?.subtitle}{" "}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sponsor-card__description">
                                    {sponsor?.start_date && (
                                        <div className="sponsor-card__date">
                                            {sponsor?.start_date}
                                            {sponsor?.end_date && <span className="sponsor-card__date">- {sponsor?.end_date}</span>}
                                        </div>
                                    )}
                                    {sponsor?.description}
                                </div>

                                <div className="sponsor-card__event-image">
                                    <img
                                        alt=""
                                        className="sponsor-card__image"
                                        src="https://www.creatopy.com/blog/wp-content/uploads/2019/06/facebook-event-cover-image-template-4.jpg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SponsorCard
