import axios from "axios"
import { setUserData } from "../Module/SharedSlices/UserDataSlice"
import { hasAuthenticationToken } from "../Module/SignIn/SigninSlice"
import StorageService, { StorageKey } from "./StorageService"

import { store } from "./store"

const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

const accessToken = StorageService.getItem(StorageKey.accessToken) as string

export const headers = {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
}

function getLocalAccessToken() {
    const accessToken = StorageService.getItem(StorageKey.accessToken)
    return accessToken
}

function getLocalRefreshToken() {
    const refreshToken = StorageService.getItem(StorageKey.refreshToken)
    return refreshToken
}

let isRefreshing = false
let apiRequests: Array<Function> = []

function onAccessTokenFetched(access_token: string | null) {
    apiRequests = apiRequests.filter(callback => callback(access_token))
}

function addApiRequests(callback: (access_token: string | null) => void) {
    apiRequests.push(callback)
}

const requestInterceptor = Axios.interceptors.request.use(
    config => {
        let access_token = getLocalAccessToken()
        if (access_token) {
            if (config.headers) {
                config.headers["Authorization"] = `Bearer ${access_token}`
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

const responseInterceptor = Axios.interceptors.response.use(
    response => {
        return response
    },
    async error => {
        const originalConfig = error.config
        if (error.response.status === 401 && originalConfig.url === "/token/refresh/") {
            logOut()
            return Promise.reject()
        } else if (originalConfig.url !== "/login/" && error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true

                if (!isRefreshing) {
                    isRefreshing = true
                    try {
                        const response = await Axios.post("/token/refresh/", {
                            refresh: getLocalRefreshToken(),
                        })

                        if (response.status === 200) {
                            const accessToken = response.data.access
                            isRefreshing = false
                            onAccessTokenFetched(accessToken)
                            StorageService.setItem(StorageKey.accessToken, accessToken)
                            Axios.defaults.headers.common["Authorization"] = "Bearer" + accessToken
                            return Axios(originalConfig)
                        } else {
                            logOut()
                            return Promise.reject()
                        }
                    } catch (_error) {
                        return Promise.reject(_error)
                    }
                }
                const retryOriginalRequest = new Promise(resolve => {
                    addApiRequests(access_token => {
                        originalConfig.headers.Authorization = "Bearer " + access_token
                        resolve(Axios(originalConfig))
                    })
                })
                return retryOriginalRequest
            }
        }

        return Promise.reject(error)
    }
)

function logOut() {
    store.dispatch(
        setUserData({
            id: "",
            email: "",
            city: "",
            country: "",
            cover_photo: "",
            first_name: "",
            last_name: "",
            description: "",
            edusiia_reasons: "",
            profile_photo: "",
        })
    )
    StorageService.clear()
    store.dispatch(hasAuthenticationToken({ hasAccessToken: false }))
}

export default Axios
