import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import StorageService, { StorageKey } from "../../App/StorageService"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import { EditProfileIcon, EditProfileSmallIcon } from "../Shared/SvgIcons"
import { setWorkExperienceData } from "../SharedSlices/WorkExperienceSlice"
import UserProfileCard from "./UserProfileCard"
import UserProfileExperienceItem from "./UserProfileExperienceItem"

function UserProfileExperience(props: { filterName: number; hasButton: boolean }) {
    const translation = useSelector(selectTranslations)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()
    const dispatch = useDispatch()
    const workExperienceState = useSelector((state: RootState) => state.workExperienceSlice.work)
    const user = StorageService.getItem(StorageKey.user) as string
    const objectUser = StorageService.parse(user)
    const navigate = useNavigate()

    const routeChange = () => {
        let path = "/all-user-experience"
        navigate(path)
    }

    const workExperienceGetRequest = () => {
        return HTTPService.get(`/user/${objectUser.id}/work/`, headers)
    }

    useEffect(() => {
        if (workExperienceState.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (workExperienceState.length > 3) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [workExperienceState])

    useEffect(() => {
        if (workExperienceState.length === 0) {
            workExperienceGetRequest()
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data
                        dispatch(setWorkExperienceData(data))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    return (
        <div className="user-profile-experience">
            <div className="user-profile-experience__title-container">
                <div className="user-profile-experience__title">{translation.myExperience}</div>
                <div className="user-profile-experience__icon">
                    <EditProfileIcon />
                </div>
                <div className="user-profile-experience__small-icon">
                    <EditProfileSmallIcon />
                </div>
            </div>
            <UserProfileCard
                children={
                    !hasNoData ? (
                        workExperienceState.slice(0, props.filterName).map(item => {
                            return (
                                <div className="user-profile-experience__item" key={item.id}>
                                    <UserProfileExperienceItem
                                        workPosition={item.work_position}
                                        companyName={item.company_name}
                                        startDate={item.start_date}
                                        endDate={item.end_date !== null ? item.end_date : "Present"}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className="user-profile-experience__empty-item">{translation.NoExperience} </div>
                    )
                }
                button={
                    props.hasButton === true && showButton === true ? (
                        <Button
                            onClick={routeChange}
                            className="button button--large button--darker-hover button--text-color-grey button__content button--margin-bottom-1 "
                        >
                            {translation.seeAllResults}
                        </Button>
                    ) : null
                }
            />
        </div>
    )
}

export default UserProfileExperience
