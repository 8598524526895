import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import { LogoIcon } from "../Shared/SvgIcons"
import TextInput from "../Shared/Primitive/TextInput"
import { forgotPasswordPostRequest } from "./ForgotPasswordServiceRequests"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../App/store"
import { selectTranslations } from "../../App/i18n/i18nSlice"

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const errorState = useSelector((state: RootState) => state.forgotpasswordSlice.forgotPasswordError)
    const statusState = useSelector((state: RootState) => state.forgotpasswordSlice.forgotPasswordStatus)

    const handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(forgotPasswordPostRequest(email))
    }

    useEffect(() => {
        if (statusState.status === true) {
            navigate("/emailconfirmation")
        }
    }, [statusState])

    return (
        <form className="forgot-password-form" onSubmit={handleSubmit}>
            <div className="forgot-password-form__logo">
                <LogoIcon />
            </div>
            <h2 className="h2 forgot-password-form__title">{translation.forgotPassword}?</h2>
            <div className="forgot-password-form__container">
                <p className="h4 forgot-password-form__text">{translation.pleaseEnterYourRegisteredEmailAddress}</p>
                <div className="forgot-password-form__input">
                    <p className="p3 forgot-password-form__text-email">{translation.email}</p>
                    <TextInput
                        textinputsize="medium"
                        type="text"
                        value={email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                    />
                    <p className="p3  forgot-password-form__error-message">{errorState.message.error}</p>
                </div>
                <div className="forgot-password-form__button">
                    <Button className="button button--medium button--color-red button--text-color-white">
                        <div className="h4"> {translation.reset}</div>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default ForgotPasswordForm
