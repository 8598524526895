import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import EdusiiaMatchingScreen from "../../Module/EdusiiaMatching/EdusiiaMatchingScreen"
import EmailConfirmationScreen from "../../Module/EmailConfirmation/EmailConfirmationScreen"
import SignupScreen from "../../Module/SignUp/SignupScreen"
import SigninScreen from "../../Module/SignIn/SigninScreen"
import ResetPasswordScreen from "../../Module/ResetPassword/ResetPasswordScreen"
import ForgotPasswordScreen from "../../Module/ForgotPassword/ForgotPasswordScreen"
import PrivateRoute from "./PrivateRoute"
import PublicRoute from "./PublicRoute"
import DashboardScreen from "../../Module/Dashboard/DashboardScreen"
import FrequentlyAskedQuestions from "../../Module/FAQ/FAQ"
import UserProfileScreen from "../../Module/UserProfile/UserProfileScreen"
import UserProfileExperienceScreen from "../../Module/UserProfile/UserProfileExperienceScreen"

const Routing = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/emailconfirmation" element={<PublicRoute Component={EmailConfirmationScreen} />} />
                    <Route path="/edusiiamatching" element={<PublicRoute Component={EdusiiaMatchingScreen} />} />
                    <Route path="/" element={<PublicRoute Component={SigninScreen} />} />
                    <Route path="/signup" element={<PublicRoute Component={SignupScreen} />} />
                    <Route path="/password-change/:uidb64/:token" element={<PublicRoute Component={ResetPasswordScreen} />} />
                    <Route path="/forgotpassword" element={<PublicRoute Component={ForgotPasswordScreen} />} />
                    <Route path="/dashboard" element={<PrivateRoute Component={DashboardScreen} />} />
                    <Route path="/FAQ" element={<PrivateRoute Component={FrequentlyAskedQuestions} />} />
                    <Route path="/user-profile" element={<PrivateRoute Component={UserProfileScreen} />} />
                    <Route path="/all-user-experience" element={<PrivateRoute Component={UserProfileExperienceScreen} />} />
                </Routes>
            </Router>
        </>
    )
}

export default Routing
