import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import { logOut } from "../Dashboard/Logout"
import Card from "../Shared/Primitive/Card"
import HeaderMenuItem from "./HeaderMenuItem"
import Image from "../Shared/Primitive/Image"

function HeaderProfileMenu() {
    let navigate = useNavigate()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)

    const routeChange = () => {
        let path = "/user-profile"
        navigate(path)
    }

    return (
        <div className="header-profile-menu">
            <Card>
                <div className="header-profile-menu__card">
                    <div onClick={routeChange} className="header-profile-menu__profile">
                        <div className="header-profile-menu__image">
                            <Image
                                className="image image__profile image__profile--size-medium "
                                src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_3.jpg"
                            />
                        </div>

                        <div className="header-profile-menu__profile-text"> {userDataState.first_name + " " + userDataState.last_name} </div>
                    </div>
                    <HeaderMenuItem title={translation.Settings} className="header-profile-menu__item header-profile-menu__item--border-bottom-3" />
                    <HeaderMenuItem title={translation.addOrganization} className="header-profile-menu__item header-profile-menu__item--border-bottom-3" />
                    <HeaderMenuItem
                        onClick={() => {
                            navigate("/FAQ")
                        }}
                        title={translation.FAQ}
                        className="header-profile-menu__item header-profile-menu__item--border-bottom-3"
                    />
                    <HeaderMenuItem
                        onClick={logOut}
                        title={translation.logOut}
                        className="header-profile-menu__item header-profile-menu__item--hover-color-red header-profile-menu__item--text-color-white"
                    />
                </div>
            </Card>
        </div>
    )
}

export default HeaderProfileMenu
