import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../Types/User"

type NewMemberData = {
    members: Array<User>
}

const initialState: NewMemberData = { members: [] }

const NewMembersSlice = createSlice({
    name: "NewMembersSlice",
    initialState: initialState,
    reducers: {
        setNewMembersData(state, action: PayloadAction<Array<User>>) {
            state.members = action.payload
        },
    },
})
export const { setNewMembersData } = NewMembersSlice.actions
export default NewMembersSlice.reducer
