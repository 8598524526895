import React from "react"
import "../../Styles/main.scss"
import UserProfileDescription from "./UserProfileDescription"
import UserProfileExperience from "./UserProfileExperience"
import UserProfileHeader from "./UserProfileHeader"
import UserProfileRooms from "./UserProfileRooms"

function UserProfileContent() {
    return (
        <div className="user-profile">
            <UserProfileHeader />
            <UserProfileDescription />
            <UserProfileExperience filterName={3} hasButton={true} />
            <UserProfileRooms hasSearch={false} filterNumber={3} />
        </div>
    )
}

export default UserProfileContent
