import React, { useEffect } from "react"
import Button from "./Primitive/Button"
import Card from "./Primitive/Card"
import "../../Styles/main.scss"
import UserCard from "./UserCard"
import HTTPService from "../../App/HTTPService"
import { NewMemberResponse } from "../../Types/Responses/NewMemberResponse"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../App/store"
import { setNewMembersData } from "../SharedSlices/NewestMembersSlice"
import { headers } from "../../App/Axios"

function NewestMembersCard() {
    const newestMemberGetRequest = () => {
        return HTTPService.get("/newestmembers/", headers)
    }
    const newMembersState = useSelector((state: RootState) => state.newMembers.members)
    const dispatch = useDispatch()

    useEffect(() => {
        if (newMembersState.length === 0) {
            newestMemberGetRequest()
                .then((response: NewMemberResponse) => {
                    if (response.status === 200) {
                        dispatch(setNewMembersData(response.data))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    return (
        <div className="new-members-card">
            <Card>
                <div className="new-members-card__members">
                    {newMembersState?.slice(0, 3).map(item => {
                        return (
                            <div key={item.id}>
                                <UserCard
                                    imageSrc={"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
                                    text={item.first_name + " " + item.last_name}
                                    subtext={"#tag"}
                                    rightText="Chat"
                                    userCardStyle={"new-members-item"}
                                    leftPanelStyle={"left-panel"}
                                    middlePanelStyle={"middle-panel"}
                                    rightPanelStyle={"right-panel"}
                                    profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                    textStyle={"text"}
                                    subtextStyle={"subtext"}
                                    rightTextStyle={"right-text"}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="new-members-card__button-container">
                    <div className="new-members-card__button">
                        <Button className="button button--large button--darker-hover button--text-color-grey button__content ">See All Results</Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default NewestMembersCard
