import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ResetPasswordResponseMessage } from "../../Types/Responses/ResetPasswordResponseMessage"

const initialStatusState = { status: false }

const ResetPasswordStatusSlice = createSlice({
    name: "resetPasswordStatus",
    initialState: initialStatusState,
    reducers: {
        getResetPasswordStatus(state = initialStatusState) {
            state.status = true
        },
    },
})

interface ErrorData {
    message: ResetPasswordResponseMessage
    status: number
}

const initialErrorState: ErrorData = { message: { password: "", confirm_password: "", non_field_errors: "" }, status: 0 }
const ResetPasswordErrorSlice = createSlice({
    name: "resetPasswordError",
    initialState: initialErrorState,
    reducers: {
        getResetPasswordError(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})

export const { getResetPasswordStatus } = ResetPasswordStatusSlice.actions
export const { getResetPasswordError } = ResetPasswordErrorSlice.actions

export default combineReducers({ resetPasswordStatus: ResetPasswordStatusSlice.reducer, resetPasswordError: ResetPasswordErrorSlice.reducer })
