import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { halloEmail, jungedEmail, nikiEmail } from "../Shared/Emails"
import Footer from "../Shared/Footer"
import Header from "../Header/Header"
import FAQAccordion from "./FAQAccordion"

function FrequentlyAskedQuestions() {
    const translation = useSelector(selectTranslations)

    return (
        <>
            <div className="FAQ">
                <Header />
                <div className="FAQ__container">
                    <h1 className="FAQ__title">
                        {translation.Fragen} & {translation.Antworten}
                    </h1>
                    <p className="FAQ__category-title">{translation.Konzeptionelles}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionOne} item={translation.FAQKonzeptionellesAnswerOne} />
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionTwo} item={translation.FAQKonzeptionellesAnswerTwo} />
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionThree} item={translation.FAQKonzeptionellesAnswerThree1}>
                                <p className="FAQ__paragraph">{translation.FAQKonzaptionellesAnswerThree2}</p>
                                <p className="FAQ__paragraph">{translation.FAQKonzaptionellesAnswerThree2}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionFour}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQKonzeptionellesAnswerFour1}
                                    <a className="FAQ__link" href="https://edusiia.com/informationen-fuer-minderjaehrige/" target="_blank" rel="noreferrer">
                                        {" "}
                                        {translation.Hier}
                                    </a>{" "}
                                    {translation.FAQKonzeptionellesAnswerFour2}{" "}
                                    <a className="FAQ__link" href={`mailto:${jungedEmail}`}>
                                        {" "}
                                        {jungedEmail}{" "}
                                    </a>
                                    {translation.FAQKonzeptionellesAnswerFour3}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionFive}>
                                <p className="FAQ__paragraph">{translation.FAQKonzeptionellesAnswerFive1}</p>
                                <p className="FAQ__paragraph">{translation.FAQKonzeptionellesAnswerFive2}</p>
                                <p className="FAQ__paragraph">{translation.FAQKonzeptionellesAnswerFive3}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionSix}>
                                <p className="FAQ__paragraph">
                                    {" "}
                                    {translation.FAQKonzeptionellesAnswerSix1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.UnserenWerten}
                                    </a>{" "}
                                    {translation.FAQKonzeptionellesAnswerSix2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionSeven}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQKonzeptionellesAnswerSeven}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/edusiia-team-ueber-uns/" target="_blank" rel="noreferrer">
                                        {translation.UberUns}
                                    </a>
                                    .
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionEight} item={translation.FAQKonzeptionellesAnswerEight} />
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionNine}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQKonzeptionellesAnswerNine1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/datenschutz/" target="_blank" rel="noreferrer">
                                        {translation.Hier}
                                    </a>{" "}
                                    {translation.FAQKonzeptionellesAnswerNine2}.
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQKonzeptionellesQuestionTen}>
                                <p className="FAQ__paragraph">{translation.FAQKonzeptionellesAnswerTen1}</p>
                                <p className="FAQ__paragraph">{translation.FAQKonzeptionellesAnswerTen2}</p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.edusiiaMatching}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.FAQEdusiiaMatchingQuestion}>
                                <p className="FAQ__paragraph">{translation.FAQEdusiiaMatchingAnswer1}</p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.FAQEdusiiaMatchingAnswer2}</strong> {translation.FAQEdusiiaMatchingAnswer3}
                                </p>
                                <p className="FAQ__paragraph">
                                    <em>{translation.FAQEdusiiaMatchingAnswer4}</em>
                                </p>
                                <p className="FAQ__paragraph">
                                    {translation.FAQEdusiiaMatchingAnswer5}{" "}
                                    <a className="FAQ__link" href={`mailto:${nikiEmail}`}>
                                        {nikiEmail}
                                    </a>{" "}
                                    {translation.FAQEdusiiaMatchingAnswer6}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.Anmeldung}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.FAQAnmeldungQuestionOne}>
                                <p className="FAQ__paragraph">
                                    {" "}
                                    {translation.FAQAnmeldungAnswerOne1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.UnserenWerten}
                                    </a>{" "}
                                    {translation.FAQAnmeldungAnswerOne2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQAnmeldungQuestionTwo} item={translation.FAQAnmeldungAnswerTwo} />
                            <FAQAccordion label={translation.FAQAnmeldungQuestionThree} item={translation.FAQAnmeldungAnswerThree} />
                            <FAQAccordion label={translation.FAQAnmeldungQuestionFour}>
                                <p className="FAQ__paragraph">{translation.FAQAnmeldungAnswerFour1}</p>
                                <p className="FAQ__paragraph">{translation.FAQAnmeldungAnswerFour2}</p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.Funktionen}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.FAQFunktionenQuestionOne}>
                                <p className="FAQ__paragraph">{translation.FAQFunktionenAnswerOne1}</p>
                                <p className="FAQ__paragraph">{translation.FAQFunktionenAnswerOne2}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQFunktionenQuestionTwo} item={translation.FAQFunktionenAnswerTwo} />
                            <FAQAccordion label={translation.FAQFunktionenQuestionThree}>
                                <p className="FAQ__paragraph">{translation.FAQFunktionenAnswerThree1}</p>
                                <p className="FAQ__paragraph">{translation.FAQFunktionenAnswerThree2}</p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.OffentlicheGruppen}</strong> {translation.FAQFunktionenAnswerThree3}
                                </p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.PrivateGruppen}</strong> {translation.FAQFunktionenAnswerThree4}
                                </p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.GeheimeGruppen}</strong> {translation.FAQFunktionenAnswerThree5}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQFunktionenQuestionFour} item={translation.FAQFunktionenAnswerFour} />
                            <FAQAccordion label={translation.FAQFunktionenQuestionFive} item={translation.FAQFunktionenAnswerFive} />
                            <FAQAccordion label={translation.FAQFunktionenQuestionSix}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQFunktionenAnswerSix1}{" "}
                                    <a className="FAQ__link" href=" https://edusiia.com/#mitmachen" target="_blank">
                                        https://edusiia.com/#mitmachen
                                    </a>{" "}
                                    {translation.FAQFunktionenAnswerSix2}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.Moderation}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.FAQModerationQuestionOne}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQModerationAnswerOne1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.UnserenWerten}
                                    </a>{" "}
                                    {translation.FAQModerationAnswerOne2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.FAQModerationQuestionTwo}>
                                <p className="FAQ__paragraph">
                                    {translation.FAQModerationAnswerTwo1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.UnserenWerten}
                                    </a>{" "}
                                    {translation.FAQModerationAnswerTwo2}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>

                    <p className="FAQ__text">
                        {translation.FAQHaveAnyQuestions}{" "}
                        <a className="FAQ__link" target="_blank" href="https://edusiia.com/kontakt/" rel="noreferrer">
                            {" "}
                            {translation.Kontaktformular}
                        </a>{" "}
                        {translation.oderEineEMailAn}{" "}
                        <a className="FAQ__link" href={`mailto:${halloEmail}`}>
                            {halloEmail}
                        </a>
                        !
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default FrequentlyAskedQuestions
