import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import UserCard from "../Shared/UserCard"

function HeaderMobileProfileCard(props: { textStyle: string; subtextStyle: string }) {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    return (
        <UserCard
            userCardStyle="header-mobile-profile-card"
            leftPanelStyle="left-panel"
            imageSrc="https://static.photocdn.pt/images/articles/2019/08/07/images/articles/2019/07/31/linkedin_profile_picture_tips-1.webp"
            profileImageStyle="image "
            middlePanelStyle="middle-panel"
            text="Individual"
            subtext={userDataState.first_name + " " + userDataState.last_name}
            textStyle={props.textStyle}
            subtextStyle={props.subtextStyle}
        />
    )
}

export default HeaderMobileProfileCard
