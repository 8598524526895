import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import { ProfileRoomsArray } from "./ProfileRoomsArray"
import UserProfileCard from "./UserProfileCard"
import UserProfileRoomsItem from "./UserProfileRoomsItem"

function UserProfileRooms(props: { filterNumber: number; hasSearch: boolean }) {
    const roomData = useSelector((state: RootState) => state.roomSlice.rooms)
    const translation = useSelector(selectTranslations)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()

    useEffect(() => {
        if (ProfileRoomsArray.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (ProfileRoomsArray.length > 3) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [ProfileRoomsArray])

    return (
        <div className="user-profile-rooms">
            <div className="user-profile-rooms__title-container">
                <div className="user-profile-rooms__title">{translation.myRooms}</div>
            </div>
            <UserProfileCard
                hasSearchField={props.hasSearch}
                children={
                    !hasNoData ? (
                        ProfileRoomsArray.slice(0, props.filterNumber).map(item => {
                            return (
                                <div className="user-profile-rooms__item" key={item.id}>
                                    <UserProfileRoomsItem
                                        image={item.image}
                                        organizationName={item.organization_name}
                                        type={item.type}
                                        roomName={item.room_name}
                                        members={item.members}
                                        date={item.date}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className="user-profile-rooms__empty-item">{translation.noRooms}</div>
                    )
                }
                button={
                    showButton === true ? (
                        <Button className="button button--large button--darker-hover button--text-color-grey button__content button--margin-bottom-1 ">
                            {translation.seeAllResults}
                        </Button>
                    ) : null
                }
            />
        </div>
    )
}

export default UserProfileRooms
