import React, { useEffect } from "react"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import Checkbox from "../Shared/Primitive/Checkbox"
import AddOrganization from "./AddOrganization"
import { LogoIcon } from "../Shared/SvgIcons"
import RadioButton from "../Shared/Primitive/RadioButton"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../App/store"
import { getSignupErrorStatus, setFormData } from "../SignUp/SignupSlice"
import StorageService, { StorageKey } from "../../App/StorageService"
import { edusiiaMatchingPostRequestWithOrganization } from "./EdusiiaMatchingServiceRequests"
import { edusiiaMatchingPostRequestWithoutOrganization } from "./EdusiiaMatchingServiceRequests"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { getEdusiiaMatchingStatus } from "./EdusiiaMatchingSlice"
import { nikiEmail } from "../Shared/Emails"

function EdusiiaMatchingForm() {
    let navigate = useNavigate()

    const translation = useSelector(selectTranslations)

    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.signupSlice.signupData)
    const statusState = useSelector((state: RootState) => state.EdusiiaMatchingSlice.edusiiaMatchingStatus)
    const signupErrorStatusState = useSelector((state: RootState) => state.signupSlice.singupErrorStatus)
    const addOrganizationError = useSelector((state: RootState) => state.EdusiiaMatchingSlice.addOrganizationError)
    const PrivacyPolicyError = useSelector((state: RootState) => state.EdusiiaMatchingSlice.privacyPolicyError)

    const localSignupForm = StorageService.parse(StorageService.getItem(StorageKey.signupForm) as string)

    const [organizationName, setOrganizationName] = React.useState("")
    const [isRepresentativeChecked, setIsRepresentativeChecked] = React.useState(false)

    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = React.useState(false)

    const [isNewsLetterChecked, setIsNewsLetterChecked] = React.useState(false)

    const [agreeRadioButton, setAgreeRadioButton] = React.useState<boolean>(true)
    const [disagreeRadioButton, setDisagreeRadioButton] = React.useState<boolean>(false)

    const radioGroupHandler = (agree: boolean) => {
        if (agree) {
            setAgreeRadioButton(false)
            setDisagreeRadioButton(true)
        } else {
            setAgreeRadioButton(true)
            setDisagreeRadioButton(false)
        }
    }

    useEffect(() => {
        dispatch(
            setFormData({
                Name: localSignupForm.name,
                Last_name: localSignupForm.lastName,
                Email: localSignupForm.email,
                Password: localSignupForm.password,
                Confirm_password: localSignupForm.confirmPassword,
                Reference_id: localSignupForm.id,
                privacy_settings: localSignupForm.privacy_settings,
            })
        )
    }, [data])

    const handleOnSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        if (organizationName.length > 0 || isRepresentativeChecked === true) {
            dispatch(
                edusiiaMatchingPostRequestWithOrganization(
                    data.Name,
                    data.Last_name,
                    data.Email,
                    data.Password,
                    data.Confirm_password,
                    data.Reference_id,
                    data.privacy_settings,
                    isPrivacyPolicyChecked,
                    agreeRadioButton,
                    isNewsLetterChecked,
                    organizationName,
                    isRepresentativeChecked,
                    translation.organizationError
                )
            )
        }
        if (organizationName.length === 0 && isRepresentativeChecked === false) {
            dispatch(
                edusiiaMatchingPostRequestWithoutOrganization(
                    data.Name,
                    data.Last_name,
                    data.Email,
                    data.Password,
                    data.Confirm_password,
                    data.Reference_id,
                    data.privacy_settings,
                    isPrivacyPolicyChecked,
                    agreeRadioButton,
                    isNewsLetterChecked
                )
            )
        }
    }

    useEffect(() => {
        if (statusState.status === true) {
            dispatch(getEdusiiaMatchingStatus({ status: false }))
            navigate("/")
        }
    }, [statusState])

    useEffect(() => {
        if (signupErrorStatusState.status === true) {
            dispatch(getSignupErrorStatus({ status: false }))
            navigate("/signup")
        }
    }, [signupErrorStatusState])
    return (
        <form className="edusiiamatching-form" onSubmit={handleOnSubmit}>
            <div className="edusiiamatching-form__logo">
                <LogoIcon />
            </div>
            <h2 className="edusiiamatching-form__title">{translation.edusiiaMatching}*</h2>
            <div className="edusiiamatching-form__radio-conainer">
                <p className=" edusiiamatching-form__radio-text">
                    {translation.edusiiaMatchingConnectViaDirectMessage} <br /> {translation.edusiiaMatchingRevokeDecisionByEmail}
                    <a className="  edusiiamatching-form__link" href={`mailto:${nikiEmail}`}>
                        {" "}
                        {nikiEmail}
                    </a>{" "}
                    {translation.edusiiaMatchingText}
                </p>
                <div className="edusiiamatching-form__radio-yes">
                    <RadioButton modifier="radio__span--red" name="matching" value={agreeRadioButton} onClick={() => radioGroupHandler(false)} />
                    <p className="">{translation.edusiiaMatchingYes}</p>
                </div>
                <div className="edusiiamatching-form__radio-no">
                    <RadioButton modifier="radio__span--red" name="matching" value={disagreeRadioButton} onClick={() => radioGroupHandler(true)} />
                    <p className="">{translation.edusiiaMatchingNo} </p>
                </div>
            </div>
            <AddOrganization
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                isRepresentative={isRepresentativeChecked}
                setIsRepresentative={setIsRepresentativeChecked}
            />
            <p className=" p3 edusiiamatching-form__error-message">
                {addOrganizationError.message.representative} {addOrganizationError.message.name}
            </p>
            <div className="edusiiamatching-form__checkbox-privacy">
                <Checkbox color="red" isChecked={isPrivacyPolicyChecked} setIsChecked={setIsPrivacyPolicyChecked} />
                <p className="edusiiamatching-form__checkbox-privacy-text">
                    {translation.agreePrivacyPolicy}{" "}
                    <a className="  edusiiamatching-form__link" href="https://edusiia.com/datenschutz/">
                        {translation.privacyPolicy}
                    </a>{" "}
                    {translation.and}{" "}
                    <a className="  edusiiamatching-form__link" href="https://edusiia.com/nutzungsbedingungen/">
                        {translation.termsOfUse}
                    </a>
                    .*
                </p>
            </div>
            <p className=" p3 edusiiamatching-form__error-message">{PrivacyPolicyError.message.privacy_policy} </p>
            <div className="edusiiamatching-form__checkbox-newsletter">
                <Checkbox color="red" isChecked={isNewsLetterChecked} setIsChecked={setIsNewsLetterChecked} />
                <p className=" edusiiamatching-form__checkbox-newsletter-text ">
                    {translation.receiveNewsLetter} <br></br> {translation.revokeNewsLetter} .*
                </p>
            </div>
            <div className="edusiiamatching-form__button">
                <Button type="submit" aria-disabled={organizationName.length > 0} className="button button--medium button--color-red button--text-color-white">
                    <div className=""> {translation.join}</div>
                </Button>
            </div>
        </form>
    )
}

export default EdusiiaMatchingForm
