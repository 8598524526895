import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SponsorData } from "../../Types/SponsorData"
import { SponsorResult } from "../../Types/SponsorResult"

const initialState: SponsorData = { events: [{ id: 0, title: "", subtitle: "", description: "", organization: "", end_date: 0, start_date: 0 }] }

const SponsorCardSlice = createSlice({
    name: "SponsorCardSlice",
    initialState: initialState,
    reducers: {
        setSponsorCardData(state, action: PayloadAction<Array<SponsorResult>>) {
            state.events = action.payload
        },
    },
})
export const { setSponsorCardData } = SponsorCardSlice.actions
export default SponsorCardSlice.reducer
