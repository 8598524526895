export const SearchOptions = [
    {
        id: 1,
        option: "Room Name",
        isSelected: false,
    },
    {
        id: 2,
        option: "Organization Name",
        isSelected: false,
    },
]
