import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"
import { SmallBookmarksIcon, SmallChatIcon, SmallHomeIcon, SmallNotificationActiveIcon, SmallRoomsIcon } from "../Shared/SvgIcons"

function HeaderBurgerMenu() {
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()
    return (
        <div className="header-burger-menu">
            <div
                className="header-burger-menu__item"
                onClick={() => {
                    navigate("/dashboard")
                }}
            >
                <div className="header-burger-menu__icon">
                    <SmallHomeIcon />
                </div>
                <div className="header-burger-menu__text">{translation.Home}</div>
            </div>
            <div className="header-burger-menu__item">
                <div className="header-burger-menu__icon">
                    <SmallRoomsIcon />
                </div>
                <div className="header-burger-menu__text">{translation.Rooms}</div>
            </div>
            <div className="header-burger-menu__item">
                <div className="header-burger-menu__icon">
                    <SmallChatIcon />
                </div>
                <div className="header-burger-menu__text">{translation.Chat}</div>
            </div>
            <div className="header-burger-menu__item">
                <div className="header-burger-menu__icon">
                    <SmallBookmarksIcon />
                </div>
                <div className="header-burger-menu__text">{translation.Bookmarks}</div>
            </div>
            <div className="header-burger-menu__item">
                <div className="header-burger-menu__icon">
                    <SmallNotificationActiveIcon />
                </div>
                <div className="header-burger-menu__text">{translation.Notifications}</div>
            </div>
        </div>
    )
}

export default HeaderBurgerMenu
