export const HeaderDummyData = {
    first_name: "Oren",
    last_name: "Papa",
    city: "Berlin",
    country: "Germany",
    profile_photo: "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg",
    cover_photo: "https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2017/08/facebook-cover.jpg",
    my_role: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum maiores eveniet autem itaque magnam ut consectetur ipsa, porro iusto qui.",
    why_at_edusiia: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum maiores eveniet autem itaque magnam ut consectetur ipsa, porro iusto qui.",
    tags: [{id:1, tag:"Education"} , ],
}
