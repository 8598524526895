import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import "../../Styles/main.scss"
import Button from "../Shared/Primitive/Button"
import { LargeLogoIcon } from "../Shared/SvgIcons"
import TextInput from "../Shared/Primitive/TextInput"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../App/store"
import { signinPostRequest } from "./SigninServiceRequests"
import { selectTranslations } from "../../App/i18n/i18nSlice"

const SignInForm = () => {
    let navigate = useNavigate()

    const translation = useSelector(selectTranslations)

    let dispatch = useDispatch()
    const errorState = useSelector((state: RootState) => state.signinSlice.signinError)
    const organizationerrorState = useSelector((state: RootState) => state.EdusiiaMatchingSlice.addOrganizationError)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        dispatch(signinPostRequest(email, password))
    }

    return (
        <>
            <form className="signin-form" onSubmit={handleSubmit}>
                <div className="signin-form__logo">
                    {" "}
                    <LargeLogoIcon />
                </div>
                <div className="signin-form__title signin-form__title--margin-top-8 ">
                    <h2 className="h2"> {translation.signin}</h2>
                </div>
                <div className="signin-form signin-form__single-input">
                    <p className="p3 signin-form__small-text ">Email</p>
                    <TextInput
                        type="text"
                        textinputsize="large"
                        value={email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                    />
                    <p className="p3  signin-form__error-message">{errorState.message.email}</p>
                </div>

                <div className="signin-form signin-form__single-input">
                    <p className="p3 signin-form__small-text ">Password</p>
                    <TextInput
                        type="password"
                        textinputsize="large"
                        value={password}
                        autoComplete="on"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    />
                    <p className="p3 signin-form__error-message">
                        {errorState.message.password} {errorState.message.non_field_errors}
                    </p>
                </div>
                <div className=" signin-form__forgot-password">
                    <Link to="/forgotpassword" className="p2 signin-form__forgot-password">
                        Forgot password?
                    </Link>

                    <p className="p3  signin-form__error-message">{organizationerrorState.message.error}</p>
                </div>
                <div className=" signin-form__button">
                    <Button type="submit" className="button button--medium button--color-red button--text-color-white">
                        <div className="h4">Sign in</div>
                    </Button>
                </div>
                <div className=" signin-form__title signin-form__title--margin-top-4 ">
                    <Link to="/signup" className="h3 signin-form__title">
                        Join Edusiia today
                    </Link>
                    <Link to="/signup" className="h3 signin-form__title">
                        Sign up
                    </Link>
                </div>
            </form>
        </>
    )
}
export default SignInForm
