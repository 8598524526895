import React from "react"
import Card from "./Primitive/Card"
import { notificationItems } from "./NotificationsArray"
import UserCard from "./UserCard"

function NotificationsDropdown() {
    return (
        <>
            <div className="notifications-dropdown">
                <Card>
                    <div className="notifications-dropdown__card">
                        {notificationItems.slice(0, 7).map(item => {
                            return (
                                <div key={item.id}>
                                    <UserCard
                                        imageSrc={item.photo}
                                        text={item.username}
                                        subtext={item.notif}
                                        rightText={item.time}
                                        userCardStyle={"notifications-card"}
                                        leftPanelStyle={"left-panel"}
                                        middlePanelStyle={"middle-panel"}
                                        rightPanelStyle={"rigt-panel"}
                                        profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                        textStyle={"text"}
                                        subtextStyle={"subtext"}
                                        rightTextStyle={"right-text"}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </div>
        </>
    )
}

export default NotificationsDropdown
