import React from "react"
import MainLayout from "../Shared/MainLayout"
import UserProfileContent from "./UserProfileContent"

function UserProfileScreen() {
    return (
        <MainLayout>
            <UserProfileContent />
        </MainLayout>
    )
}

export default UserProfileScreen
