export const ProfileRoomsArray = [
    {
        id: 1,
        image: "https://www.logodesign.net/images/abstract-logo.png",
        organization_name: "Edusiia",
        type: "public",
        room_name: " Main Class",
        members: "300",
        date: " Oct 2021",
    },

    {
        id: 2,
        image: "https://www.designfreelogoonline.com/wp-content/uploads/2018/08/Create-An-Online-Vintage-Retro-Logo-Templates.jpg",
        organization_name: "A-Company",
        type: "private",
        room_name: "Art Class",
        members: " 100",
        date: "Dec 2021",
    },

    {
        id: 3,
        image: "https://i.pinimg.com/736x/3a/e3/02/3ae3027d785aa06ade522d1d58777843--royal-logo-luxury-logo.jpg",
        organization_name: "B-Company",
        type: "private",
        room_name: "Tech Class",
        members: "55",
        date: "Mar 2020",
    },
  
]
