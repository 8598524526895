export const english = {
    signin: "Sign in",
    signup: "Sign up",
    feedWelcomeText: "Great to see you again and welcome to the dashboard of your organization.",
    hello: "Hello",
    yourRooms: "Your Rooms",
    newsMembersText: "Say hello to the newest members",
    sponsoredText: "Sponsored",
    edusiiaMatchingConnectViaDirectMessage: " We will connect you individually via direct messages with one other",
    edusiiaMatchingRevokeDecisionByEmail: "edusiia member you might not have met otherwise. You can revoke your decision at any time by sending an email to",
    edusiiaMatchingText: "Just give it a try!",
    edusiiaMatchingYes: "Yes, I would like to participate",
    edusiiaMatchingNo: "No, I do not want to participate",
    receiveNewsLetter: "I confirm that I would like to receive the edusiia newsletter in accordance with the privacy policy.",
    revokeNewsLetter: "I can revoke this consent without affecting my membership at any time and without giving reasons",
    agreePrivacyPolicy: "I confirm that I have read and agree to the",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfUse: "Terms of use",
    join: "Join",
    Fragen: "Fragen",
    Antworten: "Antworten",
    Konzeptionelles: "Konzeptionelles",
    FAQKonzeptionellesQuestionOne: "Was ist edusiia?",
    FAQKonzeptionellesAnswerOne:
        "edusiia ist die produktive Online-Community für Bildung. Wir helfen Bildungsorganisationen, ihre Bezugsgruppen langfristig untereinander und mit anderen zu vernetzen - für einen erfolgreichen Bildungswandel.",
    FAQKonzeptionellesQuestionTwo: "Was ist das Ziel dieser Plattform?",
    FAQKonzeptionellesAnswerTwo:
        "Bildung ist der Schlüssel zu positivem Wandel. Bildungsenthusiast:innen und -organisationen bekommen hier die Möglichkeit, sich langfristig interdisziplinär zu verbinden, um Herausforderungen gemeinsam zu lösen und schneller Wirkung zu erzielen. Da draußen gibt es wahnsinnig viele motivierte Akteur:innen und tolle Einzelinitiativen - es ist an der Zeit, sie einander bekannt zu machen und gemeinsam großflächig Wandel voranzutreiben.",
    FAQKonzeptionellesQuestionThree: "Wer darf bei edusiia mitmachen?",
    FAQKonzeptionellesAnswerThree1:
        "Alle, die aktiv und begeistert in der Bildung tätig sind, heißen wir herzlich bei edusiia willkommen - wir sprechen Organisationen und Menschen an, die bildungsenthusiastisch sind oder es werden wollen.",
    FAQKonzaptionellesAnswerThree2:
        "Was bedeutet bildungsenthusiastisch? Wir verbinden damit, dass man zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen ist.",
    FAQKonzaptionellesAnswerThree3:
        " Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    FAQKonzeptionellesQuestionFour: "Gibt es ein Mindestalter, um sich bei edusiia anzumelden?",
    FAQKonzeptionellesAnswerFour1:
        "Bei edusiia können alle Bildungsenthusiast:innen ab 13 Jahren mitmachen. Alle, die zwischen 13 und 17 Jahren alt sind, übermitteln edusiia vor der Registrierung in der edusiia Community eine schriftliche Einverständniserklärung ihrer Erziehungsberechtigten. So möchten wir sicherstellen, dass unsere minderjährigen Mitglieder sich mit dem Wissen und der Unterstützung ihrer Eltern in unserer Community bewegen.",
    Hier: "Hier",
    FAQKonzeptionellesAnswerFour2: "findet ihr weitere hilfreiche Informationen über Regeln und Meldemöglichkeiten bei edusiia. Mit der E-Mail-Adresse",
    FAQKonzeptionellesAnswerFour3: "geben wir minderjährigen Mitgliedern und Interessent:innen und ihren Eltern die Möglichkeit, uns gezielt zu kontaktieren.",
    FAQKonzeptionellesQuestionFive: "Was ist mit Bildungsenthusiast:innen gemeint?",
    FAQKonzeptionellesAnswerFive1: "Bildungsenthusiast:innen sind diejenigen, die aktiv und begeistert im Bildungsbereich tätig sind.",
    FAQKonzeptionellesAnswerFive2:
        "Bildungsenthusiast:innen sind zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen.",
    FAQKonzeptionellesAnswerFive3:
        "Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    FAQKonzeptionellesQuestionSix: "Was versteht edusiia unter “besserer Bildung”?",
    FAQKonzeptionellesAnswerSix1:
        "Wir halten diesen Begriff bewusst offen, da wir Bildungsenthusiast:innen aus sehr unterschiedlichen Richtungen ansprechen möchten und jede:r für ihren:seinen Bereich definieren darf, was dies bedeutet. Das verbindende Element spiegelt sich in",
    UnserenWerten: "Unseren Werten",
    FAQKonzeptionellesAnswerSix2: ", denen sich edusiia und die Bildungsentusiast:innen auf der Plattform verpflichtet fühlen.",
    FAQKonzeptionellesQuestionSeven: "Wer steckt hinter edusiia ?",
    FAQKonzeptionellesAnswerSeven:
        "Hinter edusiia steht ein interdisziplinäres Team von Bildungsenthusiast:innen, die Wandel in der Bildungswelt vorantreiben möchte. Mehr zu unseren Beweg- und Hintergründen findest du unter",
    UberUns: "Über uns",
    FAQKonzeptionellesQuestionEight: "Kostet die Mitgliedschaft bei edusiia Geld?",
    FAQKonzeptionellesAnswerEight: "Nein, die Mitgliedschaft bei edusiia ist kostenlos.",
    FAQKonzeptionellesQuestionNine: "Wie ist der Datenschutz bei edusiia geregelt?",
    FAQKonzeptionellesAnswerNine1:
        'Der Schutz deiner Daten ist für uns ein wichtiges Anliegen. Deshalb hosten wir zum Beispiel unsere Google-Schriften lokal, damit der Datenaustausch mit Google für dich entfällt. Außerdem nutzen wir das Analyse-Tool "Matomo". Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung deiner personenbezogenen Daten findet nur dort statt, deine IP-Adresse wird auch für uns nur anonymisiert angezeigt',
    FAQKonzeptionellesAnswerNine2: "findest du unsere ausführliche Datenschutzerklärung.",
    FAQKonzeptionellesQuestionTen: "Was ist der Vorteil von edusiia gegenüber Facebook-Gruppen, Bildungs-Foren etc.?",
    FAQKonzeptionellesAnswerTen1:
        "edusiia ist gezielt auf Akteur:innen der Bildungslandschaft zugeschnitten. Unsere Community regt zu Austausch, Kollaboration und dem Lösen individueller Herausforderungen an.",
    FAQKonzeptionellesAnswerTen2:
        "Unsere Mitglieder erhalten auf sie zugeschnittene Vorschläge zu neuen Themen und für sie interessante Nutzer:innen. edusiia dient dem Ziel, den Bildungsbereich voranzutreiben und wird langfristig kuratierte Inhalte für die Community zur Verfügung stellen.",
    FAQEdusiiaMatchingQuestion: 'Was ist das "edusiia Matching"?',
    FAQEdusiiaMatchingAnswer1:
        "Aktuell nutzen wir für die Community eine fertige Software-Lösung, die nur sehr bedingt an die Bedürfnisse der Mitglieder angepasst werden kann. Bis die erste Version unserer eigenen Plattform fertig ist (wir arbeiten fleißig an der Umsetzung!), dient das edusiia Matching als Möglichkeit, unsere Mitglieder einander niedrigschwellig bekannt zu machen und ihnen einen Austausch zu ermöglichen, der sie sowohl individuell als auch innerhalb ihrer Projekte voranbringt. Wir übernehmen die initiale Vorstellung, alles Weitere überlassen wir den Vernetzten.",
    FAQEdusiiaMatchingAnswer2: "Was heißt das konkret?",
    FAQEdusiiaMatchingAnswer3:
        "Beim edusiia Matching stellen wir zum Monatsanfang teilnehmende Mitglieder einander via E-Mail vor, so dass Kontakte entstehen, die sonst vielleicht nicht entstanden wären. Die Vernetzung geschieht jeweils 1-zu-1 und auf der Grundlage der Angaben im Anmeldeformular und in der Community.",
    FAQEdusiiaMatchingAnswer4: "Bitte beachte, dass wir deine Email-Adresse beim edusiia Matching mit einem anderen Mitglied der Community teilen.",
    FAQEdusiiaMatchingAnswer5: "Schreibe eine Nachricht an",
    FAQEdusiiaMatchingAnswer6: ", wenn du deine Angabe zur Teilnahme am edusiia Matching ändern möchtest oder Fragen dazu hast.",
    Anmeldung: "Anmeldung",
    FAQAnmeldungQuestionOne: "Warum muss ich mich anmelden, um mitzudiskutieren?",
    FAQAnmeldungAnswerOne1:
        "edusiia richtet sich gezielt an Bildungsenthusiast:innen, die sich für Bildung begeistern und motiviert und positiv an Bildungsthemen arbeiten. Durch die Anmeldung und damit verbundene Beantwortung zweier Fragen, möchten wir sicherstellen, dass alle Teilnehmenden sich auch als Bildungsenthusiast:innen verstehen und sich",
    FAQAnmeldungAnswerOne2: "verpflichtet fühlen.",
    FAQAnmeldungQuestionTwo: "Muss ich mich mit meinem Klarnamen anmelden?",
    FAQAnmeldungAnswerTwo: "Nein. Jedoch ist es empfehlenswert, damit dich Bekannte, Kolleg:innen und potentielle Kollaborateur:innen besser finden können.",
    FAQAnmeldungQuestionThree: "Muss ich meine:n Arbeitgeber:in angeben?",
    FAQAnmeldungAnswerThree:
        'Nein. Jedoch empfehlen wir dir die Angabe, damit dein Hintergrund und Kontext von allen Bildungsenthusiast:innen besser eingeordnet werden kann. Die entsprechende Information kannst du in deinem Profil unter "Titel" einfügen.',
    FAQAnmeldungQuestionFour: "Kann ich ein Profil für mein Unternehmen anlegen?",
    FAQAnmeldungAnswerFour1:
        "edusiia Mitglieder können in ihrer Profilbeschreibung ihre:n Arbeitgeber:in oder ihr eigenes Unternehmen nennen und vorstellen. Aktuell können sich ausschließlich Individuen bei edusiia anmelden, d. h. es gibt derzeit noch keine Möglichkeit, Profile für Unternehmen zu erstellen.",
    FAQAnmeldungAnswerFour2:
        "Zum einen möchten wir hiermit vermeiden, dass edusiia Profile vornehmlich für geschäftliche Zwecke genutzt werden. Zum anderen liegt uns gerade in der Anfangsphase viel daran, dass alle Mitglieder auf Augenhöhe miteinander in Kontakt treten, sich vernetzen und austauschen. Wir glauben fest daran, dass diese Verbindung zwischen Individuen essentiell für einen erfolgreichen Austausch und praktische Veränderung ist.",
    Funktionen: "Funktionen",
    FAQFunktionenQuestionOne: "Wie funktioniert der Feed?",
    FAQFunktionenAnswerOne1:
        "Der Feed ist die Sammlung von den auf edusiia erstellten Beiträgen, der dir und allen anderen Mitgliedern den Inhalt auf eine sehr kontextbezogene Art und Weise liefert. Jeder Feed wird auf Basis individueller Faktoren wie den gefolgten Themen und Gruppen zusammengestellt. Folgst du einer Person, erhältst du eine Benachrichtigung, sobald sie einen Beitrag veröffentlicht.",
    FAQFunktionenAnswerOne2: "Zu dem edusiia Haupt-Feed haben alle Mitglieder Zugang, nachdem sie sich angemeldet haben.",
    FAQFunktionenQuestionTwo: "Warum sollte ich eigene Lösungen auf edusiia posten?",
    FAQFunktionenAnswerTwo:
        "edusiia eignet sich sehr gut, Bildungsideen mit einer interdisziplinären Gruppe an Menschen zu diskutieren, Feedback einzuholen und zu testen. Letztendlich sind alle Teilnehmenden daran interessiert, den Bildungsbereich voranzubringen.",
    FAQFunktionenQuestionThree: "Was passiert in den Gruppen?",
    FAQFunktionenAnswerThree1:
        "Gruppen sind eine Art Sub-Communities innerhalb der großen edusiia Community. Sie geben dir eine gute Möglichkeit, ein eingegrenztes Bildungsthema mit anderen Interessierten zu diskutieren und zu bearbeiten. Beiträge, die innerhalb einer Gruppenseite veröffentlicht werden, werden automatisch dieser Gruppe zugeordnet. Nur die Mitglieder dieser Gruppe können den Inhalt der Gruppe in ihrem Feed sehen.",
    FAQFunktionenAnswerThree2: "Es gibt drei Gruppenarten:",
    OffentlicheGruppen: "Öffentliche Gruppen",
    FAQFunktionenAnswerThree3:
        'sind für alle edusiia Mitglieder zugänglich und jedes edusiia Mitglied kann den Inhalt vor dem Beitritt einsehen. Für den Beitritt zu einer öffentlichen Gruppe ist also keine Genehmigung erforderlich. Öffentliche Gruppen sind auch auf der Seite "Gruppen" sichtbar.',
    PrivateGruppen: "Private Gruppen",
    FAQFunktionenAnswerThree4:
        'hingegen sind nur für Gruppenmitglieder editierbar. Alle edusiia Mitglieder können die in der Gruppe veröffentlichten Inhalte vor dem Beitritt sehen. Der Beitritt zu einer privaten Gruppe erfordert die Genehmigung des Gruppen-Admins, des/der Gruppenmoderator:in oder des edusiia Admins. Private Gruppen sind genauso wie öffentliche Gruppen auf der Seite "Gruppen" sichtbar. ',
    GeheimeGruppen: "Geheime Gruppen",
    FAQFunktionenAnswerThree5:
        'sind wie private Gruppen, mit dem Unterschied, dass sie nicht auf der "Gruppen"-Seite aufgeführt sind, so dass Nutzer:innen sie nicht entdecken können, es sei denn, sie werden in die Gruppe eingeladen. Die einzige Möglichkeit, einer geheimen Gruppe beizutreten, ist die Einladung durch den Gruppenadmin, die Gruppenmoderator:innen oder durch edusiia Admins. Letztere können den Inhalt der Gruppe sehen, auch wenn sie nicht zu ihren Mitgliedern gehören.',
    FAQFunktionenQuestionFour: "Was sind “Themen”?",
    FAQFunktionenAnswerFour:
        'Themen sind wie Schlagworte (Tags), die zum Organisieren von Inhalten verwendet werden. Themenseiten sind öffentlich und für jeden zugänglich. Einem Beitrag können mehrere Themen zugewiesen werden. Unter "Alle Themen" werden die Themen angezeigt, denen mindestens 3 Bildungsenthusiast:innen folgen.',
    FAQFunktionenQuestionFive: "Wer kann meine Beiträge sehen?",
    FAQFunktionenAnswerFive:
        "Wenn du deine Beiträge über den Home-Feed veröffentlichst, sind sie für alle Personen, die dir folgen oder nach dem von dir gewählten Thema suchen, sichtbar. Du kannst jedoch auch auswählen, dass deine Beiträge in einer bestimmten Gruppe veröffentlicht werden und sie damit nur für die Gruppenteilnehmer:innen sichtbar sind.",
    FAQFunktionenQuestionSix: "Wie kann ich weitere Bildungsenthusiast:innen zu edusiia einladen?",
    FAQFunktionenAnswerSix1:
        "Wir arbeiten momentan an einer Lösung, die dir ermöglicht, auf einfache und schnelle Weise andere Bildungsenthusiast:innen per E-Mail einzuladen. Verschicke bitte bis dahin den Link",
    FAQFunktionenAnswerSix2: "mit der Bitte, sich über den “Ich bin dabei”-Button anzumelden.",
    Moderation: "Moderation",
    FAQModerationQuestionOne: "Wer moderiert die Beiträge?",
    FAQModerationAnswerOne1:
        "Jeder Beitrag wird sofort veröffentlicht und nicht vorher vom edusiia Team überprüft, da wir der Community das Vertrauen eines respektvollen Miteinanders entgegen bringen möchten. edusiia Community Supporter:innen sind kontinuierlich auf der Plattform aktiv und greifen bei Verstoß gegen",
    FAQModerationAnswerOne2:
        "ein. Alle teilnehmenden Bildungsenthusiast:innen auf der Plattform haben darüberhinaus die Möglichkeit, Beiträge jeglicher Art zu melden. edusiia Community Supporter:innen entscheiden dann von Fall zu Fall über die Konsequenzen der Meldung.",
    FAQModerationQuestionTwo: "Wie geht edusiia gegen Missbrauch, Hetze und Fake-Posts vor?",
    FAQModerationAnswerTwo1: "edusiia Community Supporter:innen achten darauf, dass",
    FAQModerationAnswerTwo2:
        " eingehalten werden und setzen sich, wenn notwendig, mit einzelnen Community-Mitgliedern in Kontakt, sollten sie gegen unsere Richtlinien verstoßen. Bildungsenthusiast:innen haben auch die Möglichkeit, andere Mitglieder und Beiträge bei Verstoß zu melden. Wir behalten uns vor, Mitglieder auszuschließen, sollten sie sich nicht an unsere Werte halten.",
    FAQHaveAnyQuestions: "Hast du die Antwort auf deine Frage hier nicht gefunden? Schreibe uns gerne eine Nachricht über unser",
    Kontaktformular: "Kontaktformular",
    oderEineEMailAn: "oder eine E-Mail an",
    deleteAccount: "Delete account",
    ifYouDeleteAccount: "What happens if you delete your profile",
    deleteAccountReason: "Why do you want to delete your profile?",
    acceptConsequences: "I have read and accept the consequences of deleting my profile",
    delete: "Delete",
    howOthers: "how others",
    viewMyProfile: "view my profile",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    securePassword: "Secure password",
    newSecurePassword: "New Secure password",
    repeatNewSecurePassword: "Repeat New Secure password",
    name: "Name",
    surname: "Surname",
    password: "Password",
    newPassword: "New password",
    repeatPassword: "Repeat password",
    howDidYouLearnAboutEdusiia: "How did you learn about edusiia?",
    next: "Next",
    save: "save",
    thisFieldIsRequired: "This field is required.",
    enterValidEmail: "Please enter a valid email.",
    passwordLongerThan8: "Password should be longer than 8 characters and contain at least one letter and one number.",
    passwordsDontMatch: "Passwords don't match.",
    pleaseSelectAnAnswer: "Please select an answer.",
    organizationError: "An error occurred with the adding organization proccessed. You can still add your organization later.",
    addOrganizationProfile: "Add organizational profile ",
    nameOfOrganization: "Name of the organization",
    isOrganizationRepresentative:
        "I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this profile.* (if “Name of the organization” was filled in)",
    tooltipOrganizationExample: "For example: edusiia.com",
    myRoleinEducation: "My role in the education in three raws",
    whyAmIAtEdusiia: "Why am i at Edusiia",
    Settings: "Settings",
    addOrganization: "Add organization",
    logOut: "Log out",
    FAQ: "FAQ",
    myExperience: "My Experience",
    NoExperience: "you haven't shared any experience yet",
    members: "members",
    public: "public",
    private: "private",
    myRooms: "My Rooms",
    noRooms: "you haven't joined a room yet",
    seeAllResults: "See all result",
    edusiiaMatching: "edusiia Matching",
    emailInstructionSent: "Email instruction sent",
    pleaseFollowTheInstructions: "Please follow the instructions",
    weHaveSentToYourInbox: "we have sent to your inbox.",
    forgotPassword: "Forgot password",
    pleaseEnterYourRegisteredEmailAddress: "Please enter your registered email address. We’ll send instructions to help reset your password.",
    reset: "Reset",
    resetYourPassword: "Reset your password",
    minimumCharacters: "Minimum 8 characters.",
    mustContain: "Must contain",
    upperAndLowercaseNumbersAndSimbols: "upper and lowercase, numbers and simbols.",
    confirmNewPassword: "Confirm New password",
    changePassword: "Change Password",
    theOnlineCommunity: "The online community",
    forEducation: "for education",
    weConnectOrganizations: "We connect organizations",
    andPeopleWhoWantToMake: "and people who want to make",
    educationBetter: "education better.",
    AboutUs: "About us",
    OurValues: "Our values",
    EventCalendar: "Event calendar",
    SupportEdusiia: "Support edusiia",
    YouthProtection: "Youth protection",
    privacy: "Privacy",
    Impressum: "Impressum",
    Home: "Home",
    Notifications: "Notifications",
    Rooms: "Rooms",
    Chat: "Chat",
    Bookmarks: "Bookmarks",
    Search: "Search...",
    WhoCanReachMe: "Who can reach me",
    OtherMembersCanSendMePrivateMessages: "Other members can send me private messages",
    AllowProfileInformationToBeSearched: "My profile information can be displayed in search results",
    DoYouLikeYour: "Do you like your",
    experienceInEdusiia: "experience in edusiia",
    ConsiderDonatingToOurCause: "Consider donating to our cause.",
    donate: "Donate",
}
