import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"
import { logOut } from "../Dashboard/Logout"
import HeaderMenuItem from "./HeaderMenuItem"
import HeaderMobileProfileCard from "./HeaderMobileProfileCard"
import HeaderMobileOrganizationCard from "./HeaderOrganizationCard"

function HeaderMobileMenu() {
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)

    const routeChange = () => {
        let path = "/user-profile"
        navigate(path)
    }

    return (
        <div className="header-mobile-menu">
            <div onClick={routeChange} className="header-mobile-menu__item header-mobile-menu__item--height-5-5">
                <HeaderMobileProfileCard
                    subtextStyle="subtext header-mobile-profile-card__subtext--font-weight-400"
                    textStyle="text header-mobile-profile-card__text--background-color-red"
                />
            </div>
            <div className="header-mobile-menu__item header-mobile-menu__item--height-5-5">
                <HeaderMobileOrganizationCard
                    subtextStyle="subtext header-mobile-profile-card__subtext--font-weight-700"
                    textStyle="text header-mobile-profile-card__text--background-color-gold"
                />
            </div>
            <HeaderMenuItem title={translation.Settings} className="header-mobile-menu__item" />
            <HeaderMenuItem title={translation.addOrganization} className="header-mobile-menu__item" />
            <HeaderMenuItem
                onClick={() => {
                    navigate("/FAQ")
                }}
                title={translation.FAQ}
                className="header-mobile-menu__item"
            />
            <HeaderMenuItem
                onClick={logOut}
                title={translation.logOut}
                className="header-mobile-menu__item header-mobile-menu__item--hover-color-red header-mobile-menu__item--text-color-white"
            />
        </div>
    )
}

export default HeaderMobileMenu
