import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"
import DonateButton from "./DonateButton"
import Footer from "./Footer"
import Header from "../Header/Header"
import NewestMembersCard from "./NewestMemberCard"
import ProfileCard from "./ProfileCard"
import RoomDropdown from "./RoomDropdown"
import SponsorCard from "./SponsorCard"

interface Props {
    children: JSX.Element[] | JSX.Element
}
function MainLayout({ children }: Props) {
    const translation = useSelector(selectTranslations)

    return (
        <div className="main-layout">
            <Header />

            <div className="main-layout__container">
                <div className="main-left-panel">
                    <div className="main-left-panel__container">
                        <ProfileCard />
                        <RoomDropdown />
                    </div>
                </div>
                <div className="main-middle-panel">
                    <div className="main-middle-panel__child-container"> {children} </div>
                </div>
                <div className="main-right-panel">
                    <div className="main-right-panel__container">
                        <DonateButton />
                        <p className="main-right-panel__text"> {translation.newsMembersText} </p>
                        <NewestMembersCard />
                        <p className="main-right-panel__text"> {translation.sponsoredText} </p>

                        <SponsorCard />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MainLayout
