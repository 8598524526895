import HTTPService from "../../App/HTTPService"
import { getEdusiiaMatchingStatus, getAddOrganizationError, getPrivacyPolicyError } from "./EdusiiaMatchingSlice"
import { getSignupError, getSignupErrorStatus } from "../SignUp/SignupSlice"
export const edusiiaMatchingPostRequestWithOrganization =
    (
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        confirm_password: string,
        reference_id: number,
        privacy_settings: Array<{ private_messages: boolean; search_results: boolean }>,
        privacy_policy: boolean,
        edusiia_matching: boolean,
        newsletter: boolean,
        organizationName: string,
        isRepresentativeChecked: boolean,
        organizationError: string
    ) =>
    async (dispatch: Function) => {
        const registerData = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            confirm_password: confirm_password,
            reference_id: reference_id,
            privacy_settings: privacy_settings,
            privacy_policy: privacy_policy,
            edusiia_matching: edusiia_matching,
            newsletter: newsletter,
        }

        const addOrganizationData = {
            name: organizationName,
            representative: isRepresentativeChecked,
        }

        try {
            const response = await HTTPService.post("/register/", registerData)
            if (response.status === 201) {
                await dispatch(getEdusiiaMatchingStatus({ status: true }))
                localStorage.clear()
                HTTPService.post("/organization/", addOrganizationData)
                    .then(async response => {
                        if (response.status === 201) {
                        }
                    })
                    .catch(async error => {
                        const errors = {
                            msg: error.response.data,
                            status: error.response.status,
                        }

                        await dispatch(
                            getAddOrganizationError({
                                message: {
                                    name: errors.msg.name,
                                    representative: errors.msg.representative,
                                    error: organizationError,
                                },
                                status: errors.status,
                            })
                        )
                    })
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }
            await dispatch(
                getPrivacyPolicyError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
            await dispatch(
                getSignupError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }

export const edusiiaMatchingPostRequestWithoutOrganization =
    (
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        confirm_password: string,
        reference_id: number,
        privacy_settings: Array<{ private_messages: boolean; search_results: boolean }>,
        privacy_policy: boolean,
        edusiia_matching: boolean,
        newsletter: boolean
    ) =>
    async (dispatch: Function) => {
        const registerData = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            confirm_password: confirm_password,
            reference_id: reference_id,
            privacy_settings: privacy_settings,
            privacy_policy: privacy_policy,
            edusiia_matching: edusiia_matching,
            newsletter: newsletter,
        }

        try {
            const res = await HTTPService.post("/register/", registerData)
            if (res.status === 201) {
                await dispatch(getEdusiiaMatchingStatus({ status: true }))
                localStorage.clear()
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }
            console.log(error.response.data)

            if (
                errors.msg.first_name ||
                errors.msg.last_name ||
                errors.msg.email ||
                errors.msg.password ||
                errors.msg.confirm_password ||
                errors.msg.reference_id
            ) {
                await dispatch(
                    getSignupError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )
                await dispatch(getSignupErrorStatus({ status: true }))
            }

            await dispatch(
                getPrivacyPolicyError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
            await dispatch(
                getSignupError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }
