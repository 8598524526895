import "../../../Styles/main.scss"
import React, { Key, useState } from "react"
import { VectorDown, VectorUp } from "../SvgIcons"
interface EdusiiaItems {
    id: number
    name: string
}
function Dropdown(props: { placeholder: string; items: EdusiiaItems[]; itemId: null | number; setItemId: Function } & React.HTMLAttributes<HTMLDivElement>) {
    const [expanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState("")
    const [isSelected, setIsSelected] = useState(false)

    const open = () => {
        setExpanded(!expanded)
    }

    const close = () => {
        setExpanded(false)
    }

    const handleOnClick = (items: { id: Key; name: string }) => {
        const value = items.name
        setSelected(value)
        setIsSelected(true)
        props.setItemId(items.id)
        close()
    }

    return (
        <>
            <div className="dropdown" tabIndex={0}>
                <div className="dropdown__select" onClick={open} data-testid="dropdown-select">
                    {isSelected === true ? selected : props.placeholder}
                    <div className="dropdown__icon">{expanded ? <VectorUp /> : <VectorDown />}</div>
                </div>
                {expanded && (
                    <div className="dropdown__container " data-testid="dropdown-container">
                        <div className="dropdown__content">
                            {props.items?.map((item: { id: Key; name: string }) => (
                                <div
                                    className={selected === item.name ? "dropdown__item--selected-true" : "dropdown__item"}
                                    onClick={() => handleOnClick(item)}
                                    key={item.id}
                                    data-testid={`dropdown-item${item.id}`}
                                >
                                    <p className="dropdown__text">{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
export default Dropdown
