import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"
import HeaderBurgerMenu from "./HeaderBurgerMenu"
import HeaderMobileMenu from "./HeaderMobileMenu"
import HeaderProfileMenu from "./HeaderProfileMenu"
import Image from "../Shared/Primitive/Image"
import NotificationsDropdown from "../Shared/NotificationsDropdown"
import SearchInput from "../Shared/Primitive/SearchInput"
import { BurgerMenu, EdusiiaSmallIcon, GreyVectorDown, HomeIcon, MediumLogoIcon, NotificationsActiveIcon } from "../Shared/SvgIcons"

function Header() {
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()
    let notificationRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let profieMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let mobileMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let burgerMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const [pofileMenuOpen, setPofileMenuOpen] = useState(false)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (notificationsOpen && notificationRef.current && !notificationRef.current.contains(event.target as HTMLElement)) {
                setNotificationsOpen(false)
            }
            if (pofileMenuOpen && profieMenuRef.current && !profieMenuRef.current.contains(event.target as HTMLElement)) {
                setPofileMenuOpen(false)
            }
            if (mobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as HTMLElement)) {
                setMobileMenuOpen(false)
            }
            if (burgerMenuOpen && burgerMenuRef.current && !burgerMenuRef.current.contains(event.target as HTMLElement)) {
                setBurgerMenuOpen(false)
            }
        }

        document.addEventListener("mousedown", onMouseDownHandler)
    })

    return (
        <nav className="header">
            <div
                className="header__logo"
                onClick={() => {
                    navigate("/dashboard")
                }}
            >
                <MediumLogoIcon />
            </div>
            <div className="header__search-bar">
                <SearchInput />
            </div>
            <div className="header__menu">
                <div
                    className="header__menu-home"
                    onClick={() => {
                        navigate("/dashboard")
                    }}
                >
                    <div className="header__icon">
                        <HomeIcon />
                    </div>
                    <div className="header__text">{translation.Home}</div>
                </div>

                <div ref={notificationRef} className="header__menu-notifications">
                    <div className="header__icon" onClick={() => setNotificationsOpen(!notificationsOpen)}>
                        <NotificationsActiveIcon />
                    </div>
                    <div className="header__text">{translation.Notifications}</div>
                    {notificationsOpen && <NotificationsDropdown />}
                </div>
                <div ref={profieMenuRef} className="header__profile-item">
                    <div className="header__menu-profile" onClick={() => setPofileMenuOpen(!pofileMenuOpen)}>
                        <img className="header__image" src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_3.jpg" alt="profile" />
                        <GreyVectorDown />
                    </div>
                    {pofileMenuOpen && <HeaderProfileMenu />}
                </div>
            </div>
            <div
                className="header__mobile-logo"
                onClick={() => {
                    navigate("/dashboard")
                }}
            >
                <EdusiiaSmallIcon />
            </div>
            <div className="header__mobile-search">
                <SearchInput />
            </div>

            <div className="header__mobile-menu">
                <div ref={mobileMenuRef}>
                    <div onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <Image
                            src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_3.jpg"
                            alt="profile"
                            className="image image__profile image__profile--fit image__profile--size-small"
                        />
                    </div>
                    {mobileMenuOpen && <HeaderMobileMenu />}
                </div>
                <div ref={burgerMenuRef}>
                    <div onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}>
                        <BurgerMenu />
                    </div>
                    {burgerMenuOpen && <HeaderBurgerMenu />}
                </div>
            </div>
        </nav>
    )
}

export default Header
