import React, { useEffect, useState } from "react"
import HTTPService from "../../App/HTTPService"
import StorageService, { StorageKey } from "../../App/StorageService"
import "../../Styles/main.scss"
import Card from "./Primitive/Card"
import UserCard from "./UserCard"
import { ProfileCardTypes } from "../../Types/ProfileCardTypes"
import { headers } from "../../App/Axios"

function ProfileCard() {
    const user = StorageService.getItem(StorageKey.user) as string
    const objectUser = StorageService.parse(user)
    const [organizations, setOrganizations] = useState([{ name: "", id: 0 }])
    const getOrganizationNameRequest = () => {
        return HTTPService.get(`/organization/role/?users=${objectUser.id}`, headers)
    }

    useEffect(() => {
        getOrganizationNameRequest()
            .then((response: ProfileCardTypes) => {
                if (response.status === 200) {
                    setOrganizations(response.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <div className="profile-card">
            {organizations?.slice(0, 3).map(organization => {
                return (
                    <div key={organization.id}>
                        <Card>
                            <div className="profile-card__container">
                                <UserCard
                                    imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAd8H09bHQj8r9LMiBow7xboJR4bX997Ai5w&usqp=CAU"
                                    text={organization.name}
                                    subtext="#Tech"
                                    profileImageStyle="image image__profile image__profile--fit image__profile--size-medium "
                                    userCardStyle={"item"}
                                    leftPanelStyle={"left-panel"}
                                    middlePanelStyle={"middle-panel"}
                                    textStyle={"text"}
                                    subtextStyle={"subtext"}
                                />
                            </div>
                        </Card>
                    </div>
                )
            })}
        </div>
    )
}

export default ProfileCard
