import { useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import "../../Styles/main.scss"
import Checkbox from "../Shared/Primitive/Checkbox"
import { OrangeVectorDown, OrangeVectorUp, TooltipQuestionmark } from "../Shared/SvgIcons"

import TextInput from "../Shared/Primitive/TextInput"
import Tooltip from "../Shared/Primitive/Tooltip"

const AddOrganization = ({
    organizationName,
    setOrganizationName,
    isRepresentative,
    setIsRepresentative,
}: {
    organizationName: string
    setOrganizationName: Function
    isRepresentative: boolean
    setIsRepresentative: Function
}) => {
    const [expanded, setExpanded] = useState(false)
    const translation = useSelector(selectTranslations)
    return (
        <div className="accordion">
            <div className="accordion__selector">
                <h2 className="accordion__title">{translation.addOrganizationProfile}</h2>
                <div className="tooltipIcon">
                    <Tooltip direction="top" content={translation.tooltipOrganizationExample}>
                        <TooltipQuestionmark />
                    </Tooltip>
                </div>

                <div className="accordion__button " onClick={() => setExpanded(!expanded)}>
                    {expanded ? <OrangeVectorUp /> : <OrangeVectorDown />}
                </div>
            </div>
            {expanded && (
                <div className="accordion__container">
                    <div>
                        <p className="p3 accordion__text">{translation.nameOfOrganization}</p>
                        <TextInput
                            textinputsize={"small"}
                            type="text"
                            value={organizationName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationName(event.target.value)}
                        />
                    </div>
                    <div className="accordion__checkbox">
                        <Checkbox color="red" isChecked={isRepresentative} setIsChecked={setIsRepresentative} />
                        <p className="p3 accordion__checkbox-text">{translation.isOrganizationRepresentative}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddOrganization
