import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { StorageKey } from "../../App/StorageService"
import "../../Styles/main.scss"
import { LogoIcon } from "../Shared/SvgIcons"
import { TickIcon } from "../Shared/SvgIcons"

function EmailConfirmation() {
    const [email, setEmail] = useState("")
    const translation = useSelector(selectTranslations)

    useEffect(() => {
        return setEmail(sessionStorage.getItem(StorageKey.email) as string)
    }, [])

    return (
        <div className="email-confirmation">
            <div className="email-confirmation__logo">
                {" "}
                <LogoIcon />
            </div>
            <h2 className="h2 email-confirmation__title">{translation.emailInstructionSent}</h2>
            <div className="email-confirmation__container">
                <p className="h4 email-confirmation__description">
                    {translation.pleaseFollowTheInstructions}
                    <br></br> {translation.weHaveSentToYourInbox}
                </p>
                <div className="email-confirmation__email-container">
                    <p className="p3 email-confirmation__container-text">{translation.email}</p>
                    <div className="email-confirmation__email-card">
                        <p className="p3 email-confirmation__email">
                            {email} <TickIcon />{" "}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailConfirmation
