import HTTPService from "../../App/HTTPService"
import StorageService, { StorageKey } from "../../App/StorageService"
import { getSignupStatus, setEmailError } from "./SignupSlice"

export const dropdownGetRequest = () => {
    return HTTPService.get("/referral")
}

export const emailGetRequest =
    (name: string, lastName: string, email: string, password: string, confirmPassword: string, id: string) => async (dispatch: Function) => {
        HTTPService.get(`/users/?email=${email}`, {
            email: email,
        }).then(async response => {
            if (response.data.length > 0) {
                await dispatch(setEmailError())
            } else {
                const signupForm = {
                    name: name,
                    lastName: lastName,
                    email: email,
                    password: password,
                    confirmPassword: confirmPassword,
                    id: id,
                    privacy_settings: [
                        {
                            private_messages: true,
                            search_results: true,
                        },
                    ],
                }

                StorageService.setItem(StorageKey.signupForm, JSON.stringify(signupForm))
                await dispatch(getSignupStatus({ status: true }))
            }
        })
    }
