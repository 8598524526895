type Notification = {
    id: number
    username: string
    photo: string
    notif: string
    path: string
    name: string
    time: string
}

export const notificationItems: Array<Notification> = [
    {
        id: 1,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "John Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 2,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Anna Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 3,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "jack Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 4,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 5,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 6,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 7,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 8,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
    {
        id: 9,
        photo: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Donald Smith",
        notif: "Invited you to join #Room",
        path: "./",
        name: "notif-item",
        time: "15:30 Oct 5",
    },
]
