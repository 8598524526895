import React from "react"

interface MenuItem extends React.AllHTMLAttributes<HTMLDivElement> {
    className: string
    title: string
}
function HeaderMenuItem(props: MenuItem) {
    return (
        <div {...props} className={props.className}>
            {props.title}
        </div>
    )
}

export default HeaderMenuItem
