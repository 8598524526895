import React from "react"
import UserCard from "../Shared/UserCard"
import "../../Styles/main.scss"

function HeaderMobileOrganizationCard(props: { textStyle: string; subtextStyle: string }) {
    return (
        <UserCard
            userCardStyle="header-mobile-profile-card"
            leftPanelStyle="left-panel"
            imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAd8H09bHQj8r9LMiBow7xboJR4bX997Ai5w&usqp=CAU"
            profileImageStyle="image "
            middlePanelStyle="middle-panel"
            text="Organization"
            subtext="Organization Name"
            textStyle={props.textStyle}
            subtextStyle={props.subtextStyle}
        />
    )
}

export default HeaderMobileOrganizationCard
