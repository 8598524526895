import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SigninResponseMessage } from "../../Types/Responses/SigninResponseMessage"
import { SiginErrorResponseType } from "../../Types/SigninErrorResponseType"
import { SigninTokenStatus } from "../../Types/SigninTokenStatus"

const initialState: SiginErrorResponseType = { message: { email: "", password: "", non_field_errors: "" }, status: 0 }
const SigninErrorSlice = createSlice({
    name: "signinError",
    initialState: initialState,
    reducers: {
        getSigninError(state, action: PayloadAction<SiginErrorResponseType>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})

const initialTokenState: SigninTokenStatus = { hasAccessToken: false }

const AuthenticationSlice = createSlice({
    name: "authenticationSlice",
    initialState: initialTokenState,
    reducers: {
        hasAuthenticationToken(state, action: PayloadAction<SigninTokenStatus>) {
            state.hasAccessToken = action.payload.hasAccessToken
        },
    },
})

export const { hasAuthenticationToken } = AuthenticationSlice.actions
export const { getSigninError } = SigninErrorSlice.actions
export default combineReducers({
    signinError: SigninErrorSlice.reducer,
    authenticationSlice: AuthenticationSlice.reducer,
})
