import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"

function AuthPageDescription() {
    const translation = useSelector(selectTranslations)
    return (
        <div className="description-container">
            <>
                <h1 className=" description-container__large-text description-container__large-text--color-gold description-container__large-text--font-weight">
                    <p>{translation.theOnlineCommunity} </p> <p>{translation.forEducation}</p>
                </h1>
            </>
            <>
                <h3 className=" description-container__small-text description-container__small-text--color-blue description-container__small-text--font-weight ">
                    <p> {translation.weConnectOrganizations}</p>
                    <p> {translation.andPeopleWhoWantToMake}</p>
                    <p> {translation.educationBetter}</p>
                </h3>
            </>
        </div>
    )
}

export default AuthPageDescription
