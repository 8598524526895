import React, { useState } from "react"
import "../../Styles/main.scss"
import { SearchOptions } from "./ProfileCardSearchOptionsArray"
import SearchInput from "../Shared/Primitive/SearchInput"

function UserProfileCard(props: { children?: Object; button?: JSX.Element | null; hasSearchField?: boolean }) {
    const [selectedId, setSelectedId] = useState<number | undefined>()

    return (
        <div className="user-profile-card">
            {props.hasSearchField && (
                <div className="user-profile-card__search-container">
                    <div className="user-profile-card__search-options">
                        {SearchOptions.map((item, index) => {
                            const selectedItem = selectedId === item.id ? "-selected" : "-unselected"
                            return (
                                <div onClick={() => setSelectedId(item.id)} key={index}>
                                    <div className={`user-profile-card__search-option-item user-profile-card__search-option${selectedItem}`}>{item.option}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="user-profile-card__search-input">
                        <SearchInput />
                    </div>
                </div>
            )}
            <div className="user-profile-card__content">{props.children}</div>
            <div className="user-profile-card__button">{props.button}</div>
        </div>
    )
}

export default UserProfileCard
