import React from "react"
import "../../../Styles/main.scss"

function Image(props: React.ImgHTMLAttributes<HTMLImageElement>) {
    const imageProps: React.ImgHTMLAttributes<HTMLImageElement> = {
        ...props,
    }
    return <img {...imageProps} alt={imageProps.alt}></img>
}
export default Image
