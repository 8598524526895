import React from "react"
import "../../Styles/main.scss"

function UserProfileExperienceItem(props: { workPosition: string; companyName: string; startDate: string; endDate: string }) {
    return (
        <div className="user-profile-experience-item">
            <div className="user-profile-experience-item__job-container">
                <div className="user-profile-experience-item__position">{props.workPosition}</div>
                <div className="user-profile-experience-item__company"> {props.companyName}</div>
            </div>
            <div className="user-profile-experience-item__date-container">
                <div className="user-profile-experience-item__date">{props.startDate + " - " + props.endDate} </div>
            </div>
        </div>
    )
}

export default UserProfileExperienceItem
