import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import "../../Styles/main.scss"
import { EditProfileIcon, EditProfileSmallIcon } from "../Shared/SvgIcons"
import UserProfileDescriptionItem from "./UserProfileDescriptionItem"

function UserProfileDescription() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    return (
        <div className="user-profile-description">
            <div className="user-profile-description__icon">
                <EditProfileIcon />
            </div>
            <div className="user-profile-description__small-icon">
                    <EditProfileSmallIcon />
                </div>
            <div className="user-profile-description__content">
                <div className="user-profile-description__text">
                    <UserProfileDescriptionItem title={translation.myRoleinEducation} description={userDataState.description} />
                </div>
                <div className="user-profile-description__divider"></div>
                <div className="user-profile-description__text">
                    <UserProfileDescriptionItem title={translation.whyAmIAtEdusiia} description={userDataState.edusiia_reasons} />
                </div>
            </div>
        </div>
    )
}

export default UserProfileDescription
