import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { RootState } from "../store"
import { RouterProps } from "./RoutingType"

const PublicRoute = ({ Component }: RouterProps) => {
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice)
    const authenticated = isAuthenticated.hasAccessToken

    return !authenticated ? <Component /> : <Navigate to="/dashboard" />
}
export default PublicRoute
