import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setUserData } from "../Module/SharedSlices/UserDataSlice"
import { hasAuthenticationToken } from "../Module/SignIn/SigninSlice"
import Routing from "./Routing/Routing"
import StorageService from "./StorageService"

function App() {
    const dispatch = useDispatch()
    const localUserData = StorageService.parse(StorageService.getItem("user") as string)
    dispatch(hasAuthenticationToken({ hasAccessToken: StorageService.hasItem("accessToken") }))
    useEffect(() => {
        StorageService.hasItem("user")
            ? dispatch(
                  setUserData({
                      id: localUserData.id,
                      email: localUserData.email,
                      city: localUserData.city,
                      country: localUserData.country,
                      cover_photo: localUserData.cover_photo,
                      first_name: localUserData.first_name,
                      last_name: localUserData.last_name,
                      description: localUserData.description,
                      edusiia_reasons: localUserData.edusiia_reasons,
                      profile_photo: localUserData.profile_photo,
                  })
              )
            : dispatch(
                  setUserData({
                      id: "",
                      email: "",
                      city: "",
                      country: "",
                      cover_photo: "",
                      first_name: "",
                      last_name: "",
                      description: "",
                      edusiia_reasons: "",
                      profile_photo: "",
                  })
              )
    }, [])

    return <Routing />
}
export default App
