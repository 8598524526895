import React from "react"
import "../../../Styles/main.scss"

function RadioButton(
    props: { modifier: string; name: string; value: boolean } & React.HTMLAttributes<HTMLInputElement> & React.HTMLAttributes<HTMLLabelElement>
) {
    const radioButtonProps: React.HTMLAttributes<HTMLInputElement> & React.HTMLAttributes<HTMLLabelElement> = {
        ...props,
    }

    return (
        <label data-testid="label-id" className="radio" {...radioButtonProps}>
            <input className="radio radio__input" type="radio" name={props.name} />
            <span className={`radio radio__span ${props.value === true ? props.modifier : ""}`}></span>
            {props.children}
        </label>
    )
}
export default RadioButton
