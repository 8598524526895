import React from "react"
import Button from "./Primitive/Button"
import "../../Styles/main.scss"
import { HeartIcon } from "./SvgIcons"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../App/i18n/i18nSlice"

function DonateButton() {
    const translation = useSelector(selectTranslations)
    return (
        <div className="donate-section">
            <div className="donate-section__text-container ">
                <div className="donate-section__text donate-section__text--font-weight-700">
                    {translation.DoYouLikeYour}
                    <p>{translation.experienceInEdusiia}</p>
                </div>
                <div className="donate-section__text">{translation.ConsiderDonatingToOurCause}</div>
            </div>
            <Button className=" icon-button icon-button--large icon-button--color-red icon-button--text-color-white ">
                <HeartIcon /> <div className="donate-section__button-text">{translation.donate}</div>
            </Button>
        </div>
    )
}

export default DonateButton
