import axios, { AxiosResponse } from "axios"
import Axios from "./Axios"

class HTTPService {
    get(url: string, headers?: object) {
        return Axios.get(url, headers)
    }

    post(url: string, urlData: object, headers?: object) {
        return Axios.post(url, urlData, headers)
    }

    put(url: string, urlData: object, headers?: object) {
        return Axios.put(url, urlData, headers)
    }

    delete(url: string, urlData?: object, headers?: object) {
        return Axios.delete(url, headers)
    }

    patch(url: string, urlData: object, headers?: object) {
        return Axios.patch(url, urlData, headers)
    }

    async all(promises: Promise<AxiosResponse<any, any>>[]) {
        return axios.all(promises)
    }
}

export default new HTTPService()
