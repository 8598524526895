import React from "react"
import "../../../Styles/main.scss"

interface sizeProp extends React.AllHTMLAttributes<HTMLInputElement> {
    textinputsize: "extra-small" | "small" | "medium" | "large-40" | "large"
}

function TextInput(props: sizeProp) {
    const inputProps: React.HTMLAttributes<HTMLInputElement> = { ...props }

    return (
        <>
            <div data-testid="text-input-wrapper" className={`text-input text-input--${props.textinputsize} text-input`}>
                <input
                    data-testid="text-input"
                    {...inputProps}
                    className={`text-input__element text-input__element--focus text-input__element--${props.textinputsize}`}
                />
                {props.children}
            </div>
        </>
    )
}

export default TextInput
