import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../Types/User"

const initialState: User = {
    id: "",
    email: "",
    city: "",
    country: "",
    cover_photo: "",
    first_name: "",
    last_name: "",
    description: "",
    edusiia_reasons: "",
    profile_photo: "",
}

const UserDataSlice = createSlice({
    name: "UserData",
    initialState: initialState,
    reducers: {
        setUserData(state, action: PayloadAction<User>) {
            state.id = action.payload.id
            state.email = action.payload.email
            state.city = action.payload.city
            state.country = action.payload.country
            state.cover_photo = action.payload.cover_photo
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.description = action.payload.description
            state.edusiia_reasons = action.payload.edusiia_reasons
            state.profile_photo = action.payload.profile_photo
        },
    },
})
export const { setUserData } = UserDataSlice.actions
export default UserDataSlice.reducer
